import React from 'react'
import { Form, useFormikContext } from 'formik';
// import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import FormActions from '../FormActions';
import FormContext from '../FormContext';
import Aviso from '../Aviso';
import CampoSelectNative from '../fields/CampoSelectNative';
import Loading from '../Loading';

const styles = theme => ({
  etapaTitulo: {
  },
});

const EtapaVeiculoVersao = (props) => {
  const context = React.useContext(FormContext);
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched } = useFormikContext();
  const [isBusy, setIsBusy] = React.useState(false);

  const init = async () => {
    setIsBusy(true)
    const versions = await context.getVehicleVersions()
    context.changeState({
      veiculo_versao_options: versions,
    })
    setIsBusy(false)
    if(!versions || versions.length==0){
      if(context.state.produto=="Financiamento de Veículo"){
        context.nextStepWithoutSubmitForm()
      }
    }
  }

  React.useEffect(()=>{
    init()
  },[])

  return (
    <Form>
      <CampoSelectNative
        options={context.state.veiculo_versao_options}
        name="veiculo_versao"
        label="Qual é a versão do seu veículo? *"
        value={values.veiculo_versao}
        error={errors.veiculo_versao}
        touched={touched.veiculo_versao}
        helperText={errors.veiculo_versao && touched.veiculo_versao ? errors.veiculo_versao : ''}
        onChange={(event, data) => {
          handleChange(event);
          console.log(data)
          setFieldValue('veiculo_versao', data.value);
          setFieldValue('veiculo_versao_label', data.label);
        }}
      />
      {
        isBusy &&
        <div style={{textAlign: `center`, width: `100%`}}>
          <Loading visible={true}/>
        </div>

      }
      <FormActions isSubmitting={ isSubmitting } disabled={ isBusy }  />
      <Aviso errors={errors} touched={touched}/>
    </Form>
  )
};

export default withStyles(styles)(EtapaVeiculoVersao);
