import React from 'react'
import { Form, useFormikContext } from 'formik';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import FormContext from '../FormContext';

import emprestimoPessoal from '../../assets/img/products/money-bag.png'
import emprestimoFinanciamento from '../../assets/img/products/deal.png'
import emprestimoGarantiaVeiculo from '../../assets/img/products/car-insurance.png'
import bestChoice from '../../assets/img/products/best-choice.png'
import emprestimoGarantiaImovel from '../../assets/img/products/house.png'

import theme from '../../Theme';
import { Button, Paper } from '@material-ui/core';
import { Route } from 'react-router';

const styles = theme => ({
  // melhorOpcao: {
  //   position: 'absolute',
  //   width: 50,
  //   left: -10,
  //   top: -10,
  //   zIndex: 0,
  //   fontWeight:"bold",
  //   textAlign:"center",
  //   "&::before":{
  //     top: -10,
  //     left: -5,
  //     width: 60,
  //     height: 60,
  //     content: "''",
  //     position: 'absolute',
  //     animation: "$spin 20s linear infinite",
  //     background: "#fff",
  //     border: "2px dotted #000",
  //     borderRadius: "50%",
  //     zIndex: -1,
  //   },
  // },
  '@keyframes spin': {
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  title:{
    [theme.breakpoints.down('md')]: {
      fontSize: '2.2rem',
    },
  }
});

const thumbs = {
  thumb_refin: emprestimoGarantiaVeiculo,
  thumb_pessoal: emprestimoPessoal,
  thumb_fin: emprestimoFinanciamento,
  thumb_imob: emprestimoGarantiaImovel,
}

const EtapaInicial = (props) => {
  const context = React.useContext(FormContext);
  const [otherValue, setOtherValue] = React.useState(false);
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched, submitForm } = useFormikContext();
  const { fields, etapa } = props;
  
  let produtoOptions = [
    {
      label: "SE VOCÊ QUER OS MENORES JUROS",
      value: "Refinanciamento de Veículo",
      desc: "Empréstimo com Veículo em Garantia",
      img: "thumb_refin",
      route: "/solicitacao-emprestimo-garantia-veiculo",
      melhorOpcao: true,
    },
    {
      label: "USE SEU PRÓPRIO IMÓVEL PARA CONSEGUIR VALORES MAIS ALTOS",
      value: "Refinanciamento Imobiliário",
      desc: "Empréstimo com Imóvel em Garantia",
      img: "thumb_imob",
      route: "/solicitacao-emprestimo-com-imovel-em-garantia",
    },
    // {
    //   label: "SE VOCÊ NÃO QUER COLOCAR GARANTIAS",
    //   value: "Empréstimo Pessoal",
    //   desc: "Empréstimo Pessoal sem Garantias",
    //   img: "thumb_pessoal",
    //   route: "/solicitacao-emprestimo-pessoal",
    // },
    {
      label: "SE VOCÊ QUER COMPRAR UM VEÍCULO",
      value: "Financiamento de Veículo",
      desc: "Financiamento de veículo",
      img: "thumb_fin",
      route: "/solicitacao-financiamento-de-veiculo",
    },
  ];

  return(
    <Form>
      <Typography variant="h2" className={props.classes.title} style={{textAlign:"center", width:"100%", marginBottom:theme.spacing(2)}}>Escolha o tipo de empréstimo:</Typography>
      <div style={{width:"100%", display:"flex", flexWrap:"wrap", justifyContent:"center", alignItems:"center"}}>
      {
          produtoOptions.map((option) =>
            <Paper style={{
              width:250,
              height:480,
              padding:theme.spacing(2),
              margin:theme.spacing(),
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              position: 'relative',
              }} key={option.img}>
              {
                option.melhorOpcao ? <img src={bestChoice} alt="Melhor opção" style={{ position: 'absolute', top: -30, left: -40}} width="100" />: null
              }  
              <img style={{
                width: 100,
                margin: `${theme.spacing()}px auto`,
                  display: 'block',
              }} src={thumbs[option.img]} />
              <Typography variant="h6" style={{
                display:"block",
                width:"80%",
                margin:`${theme.spacing(2)}px auto`,
                textAlign:"center",
                lineHeight:"100%",
                fontWeight:"bold",
              }}>{option.desc}</Typography>
              <Typography variant="h5" style={{
                // height:80,
                lineHeight:"100%",
                margin:`${theme.spacing()}px 0`,
                textAlign:"center",
              }}>{option.label}</Typography>
              <Route render={({ history }) => (
                <Button variant="contained" style={{
                  background:"#FFD20D",
                  fontSize:"1.25rem",
                  // margin:`${theme.spacing(2)}px auto 0`,
                }} onClick={(e)=>{
                  // setFieldValue('produto',option.value);
                  // setFieldValue('fonteDoLead',ProductsList[option.route.replace("/","")].leadSrc);
                  // submitForm();
                  history.push(option.route)
                  window.scrollTo({top: 0, behavior: 'smooth'});
                }}>SOLICITAR</Button>
              )} />
            </Paper>
        )
      }
      </div>
    </Form>
  )
};

export default withStyles(styles)(EtapaInicial);