import moment from 'moment';
import * as Yup from 'yup';
import CNPJ from "@fnando/cnpj/dist/node";
import CPF from "@fnando/cpf/dist/node";
import { stripRenda } from './Utils';
import routesFlows from "../lib/RoutesFlows";
import formatMoney from '../lib/formatMoney'

export default function getValidationSchema(step, initialValues, context) {

  const validations = {};

  const minMontante = (context.state.parametros && context.state.parametros[ context.state.produto ] ? context.state.parametros[ context.state.produto ].minMontante : 5000)

  validations["EtapaQuantoPrecisa"] = Yup.object().shape({

    montante: Yup.string()
      .required('Campo "Quanto você precisa" é obrigatório.')
      .test('test-montante', `O valor mínimo do empréstimo deve ser de ${formatMoney(minMontante)}.`, (val) => Number(stripRenda(val)) >= minMontante )
      .nullable(),

  });

  validations["EtapaContaSantander"] = Yup.object().shape({

    conta_santander_debito_automatico: initialValues.hasOwnProperty('conta_santander_debito_automatico') ?
      Yup.string().required('Por favor, selecione uma opção.') : null,

    banco_agencia: initialValues.hasOwnProperty('banco_agencia') ? Yup.string()
    .when(['conta_santander_debito_automatico'], {
      is: (conta_santander_debito_automatico) => {
        return (conta_santander_debito_automatico=='Sim');
      },
      then: Yup.string()
      .matches(/^\d+$/, "Por favor, digite apenas números.")
      .required('Digite o número de sua agência do Santander para débito em conta.')
      ,
    }) : null,

    banco_conta: initialValues.hasOwnProperty('banco_conta') ? Yup.string()
    .when(['conta_santander_debito_automatico'], {
      is: (conta_santander_debito_automatico) => {
        return (conta_santander_debito_automatico=='Sim');
      },
      then: Yup.string()
      .required('Digite o número de sua conta do Santander para débito em conta.')
      .matches(/^\d+$/, "Por favor, digite apenas números.")
      .test('banco_conta', 'Sua conta deve ter entre 3 e 13 digitos.',
        function (value) {
          if(value==undefined) return;
          const characters = value.toString().length;
          if (characters < 3) {
            return false
          }
          if (characters > 13) {
            return false
          }
          return true;
          }),
    }) : null,

  });

  validations["EtapaSobreVoce"] = Yup.object().shape({

    objetivo: initialValues.hasOwnProperty('objetivo') ? Yup.string().required('Por favor, preencha seu objetivo.').nullable() :null,
    prazo: initialValues.hasOwnProperty('prazo') ? Yup.string()
    .required('Por favor, insira o prazo do seu crédito.')
    .test('test-prazo', 'Valor acima do permitido.', (val) => Number(stripRenda(val)) <= (context.state.parametros && context.state.parametros[ context.state.produto ] ? context.state.parametros[ context.state.produto ].maxPrazo : 240) )
    .test('test-prazo', 'Valor abaixo do permitido.', (val) => Number(stripRenda(val)) >= (context.state.parametros && context.state.parametros[ context.state.produto ] ? context.state.parametros[ context.state.produto ].minPrazo : 6) )
    .nullable()
    : null,

    restricao: !context.state.values.bv_alto_potencial_de_aprovacao && initialValues.hasOwnProperty('restricao') ? Yup.string().required('Por favor, diga se você tem alguma restrição.').nullable() : null,
    restricao_valor: Yup.string().when(['restricao'], {
      is: (pRestricao) => {
        return pRestricao == "Sim";
      },
      then: Yup.string().required('Por favor, informe o valor da restrição.'),
    }),
    possui_saldo_fgts: initialValues.hasOwnProperty('possui_saldo_fgts') ? Yup.string()
    .when(['restricao_valor'], {
      is: (restricao_valor) => {
        return (restricao_valor && restricao_valor=='Maior que R$ 2 mil');
      },
      then: context.state.feature_flag_offer_fgts_in_egv ?
        Yup.string().required('Informe se possui saldo no FGTS.')
        : Yup.string().nullable()
    } ) : null,

  });

  validations["EtapaVeiculoFinanciamento"] = Yup.object().shape({

    veiculo_tipo: Yup.string().required('Você deve escolher o tipo do veículo.'),

    veiculo_marca: Yup.string()
    .when(['veiculo_tipo','possui_carro'], {
      is: (veiculo_tipo, possui_carro) => {
        if(possui_carro && veiculo_tipo=='moto'){
          return (veiculo_tipo!='moto' && possui_carro!='não');
        }else{
          return (veiculo_tipo != undefined)
        }
      },
      then: Yup.string().required('Insira a marca do seu veículo.'),
    }),

    veiculo_modelo: Yup.string()
    .when(['veiculo_tipo','possui_carro'], {
      is: (veiculo_tipo, possui_carro) => {
        if(possui_carro && veiculo_tipo=='moto'){
          return (veiculo_tipo!='moto' && possui_carro!='não');
        }else{
          return (veiculo_tipo != undefined)
        }
      },
      then: Yup.string().required('Por favor, insira o modelo de seu veículo.'),
    }),

    veiculo_ano: Yup.string()
    .when(['veiculo_tipo','possui_carro'], {
      is: (veiculo_tipo, possui_carro) => {
        if(possui_carro && veiculo_tipo=='moto'){
          return (veiculo_tipo!='moto' && possui_carro!='não');
        }else{
          return (veiculo_tipo != undefined)
        }
      },
      then: Yup.string().required('Digite o ano do veículo.'),
    }),

    quitado: initialValues.hasOwnProperty('quitado') ? Yup.string()
    .when(['veiculo_tipo','possui_carro'], {
      is: (veiculo_tipo, possui_carro) => {
        if(possui_carro && veiculo_tipo=='moto'){
          return (veiculo_tipo!='moto' && possui_carro!='não');
        }else{
          return (veiculo_tipo != undefined)
        }
      },
      then: Yup.string().required('Informe se seu veículo está quitado.')
    } ) : null,

    banco_financiamento: initialValues.hasOwnProperty('quitado') ? Yup.string()
    .when(['veiculo_tipo','quitado'],{
      is: (veiculo_tipo, quitado) => {
        return veiculo_tipo != undefined && quitado && quitado=='Não';
      },
      then: Yup.string().required('Por favor, selecione o banco que está financiando o veículo.')
    }) : null,

    proprietario: initialValues.hasOwnProperty('proprietario') ? Yup.string()
    .when(['veiculo_tipo','possui_carro'], {
      is: (veiculo_tipo, possui_carro) => {
        if(possui_carro && veiculo_tipo=='moto'){
          return (veiculo_tipo!='moto' && possui_carro!='não');
        }else{
          return (veiculo_tipo != undefined)
        }
      },
      then: Yup.string().required('Informe se você é o proprietário do veículo.')
    } ) : null,

    tem_veiculo_placa: initialValues.hasOwnProperty('veiculo_placa') ? Yup.string().required('Por favor, diga se você sabe a placa do veículo.') : null,

    veiculo_placa: initialValues.hasOwnProperty('veiculo_placa') ? Yup.string()
    .when(['veiculo_tipo','tem_veiculo_placa'], {
      is: (veiculo_tipo, tem_veiculo_placa) => {
        return (veiculo_tipo != undefined && tem_veiculo_placa && tem_veiculo_placa=='Sim');
      },
      then: Yup.string()
      .required('Informe a placa do veículo.')
      .matches(/[aA-zZ]{3}[0-9][aA-zZ0-9][0-9]{2}/, 'Formato da placa do veículo incorreto.'),
    } ) : null,

  });

  validations["EtapaComoEncontrar"] = Yup.object().shape({

    nomeCompleto: Yup.string().nullable()
    .matches(/^[^0-9]{2,}(\s[^\s0-9]{2,})+(\s)?$/,'Por favor, verifique o nome.')
    .required('Por favor, preencha seu nome completo.'),

    celular: Yup.string().nullable()
    .required('Por favor, digite seu ceular.')
    .test('test-cel', 'Ops, o celular é inválido.',
      function(value) {
        return value ? value.match(/^\(\d{2}\)\d{5}\-\d{4}$/) : true;
      }
    ),

    email: Yup.string().nullable()
    .required('O campo email é obrigatório.')
    .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,'Formato de email está incompleto.'),

    cep: initialValues.hasOwnProperty('cep') ? Yup.string().nullable()
    .required('Ops, você esqueceu seu cep.')
    .matches(/^\d{5}\-\d{3}$/,'CEP incompleto')
    : null,

    cpf: initialValues.hasOwnProperty('cpf') ? Yup.string()
      .required('Por favor, insira seu CPF.')
      .test('test-cpf', 'Ops, o CPF é inválido.',
        function(value) {
          return CPF.isValid(value);
        }
    ) : null,

    terms: initialValues.hasOwnProperty('terms') ? Yup.bool()
    .required('Aceite o termo para continuar.')
    .equals([true],'Para continuar, é necessário aceitar o termo de consentimento.')
      : null,


  });

  validations["EtapaDadosBancarios"] = Yup.object().shape({

    banco_tipo_conta: initialValues.hasOwnProperty('banco_tipo_conta') ? Yup.string()
    .required('Por favor, selecione o tipo de sua conta.') : null,

    banco: initialValues.hasOwnProperty('banco') ? Yup.string()
    .required('Por favor, selecione o banco.') : null,

    banco_agencia: initialValues.hasOwnProperty('banco_agencia') ? Yup.string()
    .matches(/^\d+$/, "Por favor, digite apenas números.")
    .required('O campo agência é obrigatório.')
    .test('banco_agencia', 'Sua conta deve ter 4 caracteres .', function (value) {
      if(value==undefined) return;
      const characters = value.toString().length;
      if (characters < 4) {
        return false
      }
      return true;
    })
    : null,

    banco_conta: initialValues.hasOwnProperty('banco_conta') ? Yup.string()
    .required('O campo conta é obrigatório.')
    .matches(/^\d+$/, "Por favor, digite apenas números.")
    .test('banco_conta', 'Sua conta deve ter entre 3 e 13 digitos.',
      function (value) {
        if(value==undefined) return;
        const characters = value.toString().length;
        if (characters < 3) {
          return false
        }
        if (characters > 13) {
          return false
        }
        return true;
        })
    : null,

  });

  validations["EtapaEnderecoFGTS"] = Yup.object().shape({

    cep: Yup.string().nullable()
      .required('Ops, você esqueceu seu cep.')
      .matches(/^\d{5}\-\d{3}$/, 'CEP incompleto'),
    endereco: Yup.string().required('Por favor, digite seu endereço.'),
    estado: Yup.string().required('Selecione seu estado.'),
    cidade: Yup.string().required('Digite sua cidade.'),
    bairro: Yup.string().required('Por favor, diga qual seu bairro.'),
    numero: Yup.number()
      .typeError('Ops, digite apenas números no número de sua residência.')
      .required('Por favor, insira o número de sua residência.'),

  });

  validations["EtapaDocIdentificacao"] = Yup.object().shape({

    rg: Yup.string().required('Insira seu RG.')
    .test('rg-valida-caracteres', 'Ops, Digite somente números no RG.',
      function (value) {
        let rgValue = value || '';
        let characterNull = true;
        const stringRg = rgValue.split('')
        stringRg.forEach(char => {
          if (!(!isNaN(parseFloat(char)) && isFinite(char))) {
            characterNull = false
          }
        })

        return characterNull
      })
    .test('rg-valida-digitos', 'Ops, o RG deve conter 5 ou mais digitos.',
      function (value) {
        return value.length < 5 ? false : true
      }),
    // rg_orgao_emissor: Yup.string().required('Escolha seu estado.'),
    // rg_data_emissao: Yup.string()
    // .required('Digite sua data de nascimento.')
    // .test('test-passado', 'Ops, a data é inválida (Formato dd/mm/aaaa).',
    //   function(value) {
    //       const data = moment(value, "DD/MM/YYYY", true);
    //       return data.isValid() && data.isBefore();
    //   }
    // )
    // .test('test-maximo', 'Ops, a data é inválida (Formato dd/mm/aaaa).',
    //   function(value) {
    //       const data = moment(value, "DD/MM/YYYY", true);
    //       const data_now = moment();
    //       return data.isValid() && data_now.diff(data,'years') <= 100;
    //   }
    // ),
    // rg_estado_emissao: Yup.string().required('Selecione seu estado.'),
    // naturalidade: Yup.string().required('Selecione seu estado de naturalidade.'),
    estado_nascimento: Yup.string().required('Informe seu estado de nascimento.'),
    nome_mae: Yup.string()
    .matches(/^[^0-9]{2,}(\s[^\s0-9]{2,})+(\s+)?$/,'Por favor, verifique o nome.')
    .required('Digite o nome de sua mãe.'),

  });

  validations["EtapaDadosComplementaresFGTS"] = Yup.object().shape({

    data_nascimento: initialValues.hasOwnProperty('data_nascimento') ? Yup.string()
    .required('Digite sua data de nascimento.')
    .test('test-passado', 'Ops, a data é inválida (Formato dd/mm/aaaa).',
        function(value) {
            const data = moment(value, "DD/MM/YYYY", true);
            return data.isValid() && data.isBefore();
        }
    ): null,
    nacionalidade: initialValues.hasOwnProperty('nacionalidade') ? Yup.string()
      .required('Diga qual sua nacionalidade.') : null,

    // estado_nascimento: Yup.string().required('Informe seu estado de nascimento.'),
    // cidade_nascimento: Yup.string().required('Informe sua cidade de nascimento.'),

    estado_civil: initialValues.hasOwnProperty('estado_civil') ? Yup.string()
    .required('Escolha um estado civil.') : null,

    genero: initialValues.hasOwnProperty('genero') ? Yup.string()
    .required('Selecione seu gênero.') : null,

  });

  validations["EtapaOndeEncontrar"] = Yup.object().shape({

    nome: initialValues.hasOwnProperty('nome') ? Yup.string()
    .matches(/^[^0-9]{2,}(\s[^\s0-9]{2,})+(\s)?$/,'Por favor, verifique o nome.')
    .required('Por favor, preencha seu nome.') : null,

    celular: initialValues.hasOwnProperty('celular') ? Yup.string()
    .required('Por favor, digite seu ceular.')
    .test('test-cel', 'Ops, o celular é inválido.',
      function(value) {
        return value ? value.match(/^\(\d{2}\)\d{5}\-\d{4}$/) : true;
      }
    ): null,

    tel: initialValues.hasOwnProperty('tel') ? Yup.string()
    // .required('Por favor, digite seu ceular.')
    .test('test-tel', 'Ops, o telefone fixo é inválido.',
      function(value) {
        return value ? value.match(/^\(\d{2}\)[0-8]\d{3}\-\d{4}$/) : true;
      }
    ): null,

    email: initialValues.hasOwnProperty('email') ? Yup.string()
    .required('O campo email é obrigatório.')
    .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,'Formato de email está incompleto.')
    : null,

    cep: initialValues.hasOwnProperty('cep') ? Yup.string()
    .required('Ops, você esqueceu seu cep.')
    .matches(/^\d{5}\-\d{3}$/,'CEP incompleto')
    : null,

    endereco: Yup.string().required('Por favor, digite seu endereço.'),

    estado: Yup.string().required('Selecione seu estado.'),

    cidade: Yup.string().required('Digite sua cidade.'),

    bairro: Yup.string().required('Por favor, diga qual seu bairro.'),

    numero: Yup.number()
      .typeError('Ops, digite apenas números no número de sua residência.')
      .required('Por favor, insira o número de sua residência.'),

    tempo_residencia: initialValues.hasOwnProperty('tempo_residencia') ? Yup.string().required('Informe quanto tempo você mora em sua residência.') : null,

    tipo_casa: initialValues.hasOwnProperty('tipo_casa') ? Yup.string().required('Defina o tipo de casa que você mora.') : null,
    // tipo_residencia: initialValues.hasOwnProperty('tipo_residencia') ? Yup.string().required('Defina o tipo de casa que você mora.') : null,
    // qtdeMesesResidencia: initialValues.hasOwnProperty('qtdeMesesResidencia') ? Yup.number()
    // .typeError('Ops, digite apenas números.')
    // .required('Por favor, insira a quantidade de meses no imóvel.')
    // : null,
  });

  validations["EtapaDadosPessoais"] = Yup.object().shape({
    data_nascimento: Yup.string()
    .required('Digite sua data de nascimento.')
    .test('test-passado', 'Ops, a data é inválida (Formato dd/mm/aaaa).',
        function(value) {
            const data = moment(value, "DD/MM/YYYY", true);
            return data.isValid() && data.isBefore();
        }
    )
    .test('test-maioridade', 'Você precisa ter mais de 18 anos.',
        function(value) {
            const data = moment(value, "DD/MM/YYYY", true);
            const data_now = moment();
            return data.isValid() && data_now.diff(data,'years') >= 18;
        }
    )
    .test('test-maximo', 'Ops, a data é inválida (Formato dd/mm/aaaa).',
        function(value) {
            const data = moment(value, "DD/MM/YYYY", true);
            const data_now = moment();
            return data.isValid() && data_now.diff(data,'years') <= 100;
        }
    ),

    estado_nascimento: Yup.string().required('Informe seu estado de nascimento.'),
    cidade_nascimento: Yup.string().required('Informe sua cidade de nascimento.'),

    estado_civil: initialValues.hasOwnProperty('estado_civil') ? Yup.string()
    .required('Escolha um estado civil.') : null,

    conjuge_cpf: initialValues.hasOwnProperty('conjuge_cpf') ? Yup.string()
    .when(['estado_civil'], {
      is: (val) => ["Casado","União Estável"].includes(val),
      then: Yup.string()
      .required('Por favor, insira o CPF do conjuge.')
      .test('test-cpf', 'Ops, o CPF é inválido.',
        function(value) {
          return CPF.isValid(value);
        }
      )
    }) : null,

    conjuge_nomeCompleto: initialValues.hasOwnProperty('conjuge_nomeCompleto') ? Yup.string()
    .when(['estado_civil'], {
      is: (val) => ["Casado","União Estável"].includes(val),
      then: Yup.string()
      .matches(/^[^0-9]{2,}(\s[^\s0-9]{2,})+(\s)?$/,'Por favor, verifique o nome.')
      .required('Por favor, preencha o nome de seu cônjuge.')
    }) : null,

    conjuge_email: initialValues.hasOwnProperty('conjuge_email') ? Yup.string().nullable()
    .when(['estado_civil'], {
      is: (val) => ["Casado","União Estável"].includes(val),
      then: Yup.string()
      .required('O campo email é obrigatório.')
      .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,'Formato de email está incompleto.')
    }) : null,
    
    conjuge_celular: initialValues.hasOwnProperty('conjuge_celular') ? Yup.string().nullable()
    .when(['estado_civil'], {
      is: (val) => ["Casado","União Estável"].includes(val),
      then: Yup.string().nullable()
      .required('Por favor, digite seu ceular.')
      .test('test-cel', 'Ops, o celular é inválido.',
        function(value) {
          return value ? value.match(/^\(\d{2}\)\d{5}\-\d{4}$/) : true;
        }
      )
    }) : null,

    conjuge_data_nascimento: initialValues.hasOwnProperty('conjuge_data_nascimento') ? Yup.string().nullable()
    .when(['estado_civil'], {
      is: (val) => ["Casado","União Estável"].includes(val),
      then: Yup.string().nullable()
      .required('Digite sua data de nascimento.')
      .test('test-passado', 'Ops, a data é inválida (Formato dd/mm/aaaa).',
          function(value) {
              const data = moment(value, "DD/MM/YYYY", true);
              return data.isValid() && data.isBefore();
          }
      )
      .test('test-maioridade', 'Você precisa ter mais de 18 anos.',
          function(value) {
              const data = moment(value, "DD/MM/YYYY", true);
              const data_now = moment();
              return data.isValid() && data_now.diff(data,'years') >= 18;
          }
      )
      .test('test-maximo', 'Ops, a data é inválida (Formato dd/mm/aaaa).',
          function(value) {
              const data = moment(value, "DD/MM/YYYY", true);
              const data_now = moment();
              return data.isValid() && data_now.diff(data,'years') <= 100;
          }
      ),
    }) : null,

    genero: initialValues.hasOwnProperty('genero') ? Yup.string()
    .required('Selecione seu gênero.') : null,


  });

  validations["EtapaDadosProfissionais"] = Yup.object().shape({

    ocupacao: Yup.string().required('Selecione sua ocupação.'),

    renda: Yup.string()
    .required('Por favor, diga sua renda.')
    .test('test-renda', 'Valor da renda está fora dos limites permitidos.', (val) => {
      return Number(stripRenda(val)) >= 1039 && Number(stripRenda(val)) <= 50000;
    }),

    cargo: Yup.string()
    .when(['ocupacao'], {
      is: (val) => val != "Empresário(a)" && val!="Autônomo(a)/ Prof. Liberal" && val!="Aposentado(a)",
      then: Yup.string().required('Informe seu cargo.')
    }),

    tempo_servico: Yup.string()
    .when(['ocupacao'], {
        is: (val) => val != "Empresário(a)" && val!="Aposentado(a)",
        then: Yup.string().required('Insira o tempo de serviço.')
    }),

    tem_cnpj: Yup.string()
        .when(['ocupacao'], {
            is: "Autônomo(a)/ Prof. Liberal",
            then: Yup.string().required('Selecione se possui CNPJ.')
    }),

    cnpj: Yup.string()
        .when(['ocupacao','tem_cnpj'], {
            is: (ocupacao,tem_cnpj) => ocupacao == "Empresário(a)" || (ocupacao=="Autônomo(a)/ Prof. Liberal" && tem_cnpj=="Sim"),
            then: Yup.string()
                .required('Por favo, preencha com o CNPJ.')
                .test('test-cnpj', 'Ops, o CNPJ é inválido.',
                    function(value) {
                        return CNPJ.isValid(value);
                    }
                )
    }),

    aposentado_num_beneficio: Yup.string()
    .when(['ocupacao'], {
      is: (val) => ( val == "Aposentado(a)" ),
      then: Yup.string()
        .max(20,'Digite no máximo 20 digitos para seu número do benefício.')
        .required('Digite seu número de benefício.'),
    }),

    conjuge_compoe_renda: initialValues.hasOwnProperty('conjuge_compoe_renda') ? Yup.string()
    .test('test', 'Informe se seu cônjuge irá compor renda.',
      function(value) {
        if( ["Casado","União Estável"].includes(context.state.values.estado_civil) ){
          return value!=undefined && value!=''
        }else{
          return true
        }
      }
    )
    : null,

    conjuge_ocupacao: initialValues.hasOwnProperty('conjuge_ocupacao') ? Yup.string()
    .when(['conjuge_compoe_renda'], {
      is: "Sim",
      then: Yup.string().required('Informe a ocupação de seu cônjuge.')
    })
    : null,

    conjuge_cargo: initialValues.hasOwnProperty('conjuge_cargo') ? Yup.string()
    .when(['conjuge_compoe_renda'], {
      is: "Sim",
      then: Yup.string().required('Informe a profissão de seu cônjuge.'),
    })
    : null,

    conjuge_renda: initialValues.hasOwnProperty('conjuge_renda') ? Yup.string()
    .when(['conjuge_compoe_renda'], {
      is: "Sim",
      then: Yup.string().required('Informe a renda de seu cônjuge.'),
    })
    : null,

    conjuge_comprovante_renda_tipo: initialValues.hasOwnProperty('conjuge_comprovante_renda_tipo') ? Yup.string()
    .when(['conjuge_compoe_renda'], {
      is: "Sim",
      then: Yup.string().required('Informe como seu cônjuge comprova renda.'),
    })
    : null,

  });

  validations["EtapaRgCpf"] = Yup.object().shape({

    cpf: initialValues.hasOwnProperty('cpf') ? Yup.string()
    .required('Por favor, insira seu CPF.')
    .test('test-cpf', 'Ops, o CPF é inválido.',
      function(value) {
        return CPF.isValid(value);
      }
    ) : null,

    rg: Yup.string().required('Insira seu RG.')
    .test('rg-valida-caracteres', 'Ops, Digite somente números no RG.',
      function (value) {
        if(!value) return false
        let characterNull = true;
        const stringRg = value.split('')
        stringRg.forEach(char => {
          if (!(!isNaN(parseFloat(char)) && isFinite(char))) {
            characterNull = false
          }
        })

        return characterNull
      })
    .test('rg-valida-digitos', 'Ops, o RG deve conter 5 ou mais digitos.',
      function (value) {
        if(!value) return false
        return value.length < 5 ? false : true
      }
    ),
    nome_mae: initialValues.hasOwnProperty('nome_mae') ? Yup.string()
    .matches(/^[^0-9]{2,}(\s[^\s0-9]{2,})+(\s)?$/,'Por favor, verifique o nome.')
    .required('Digite o nome de sua mãe.') : null,

  });

  validations["EtapaReferencias"] = Yup.object().shape({

    nome_mae: Yup.string()
    .matches(/^[^0-9]{2,}(\s[^\s0-9]{2,})+(\s)?$/,'Por favor, verifique o nome.')
    .required('Digite o nome de sua mãe.'),

  });

  const minImovelValor = (context.state.parametros && context.state.parametros[ context.state.produto ] ? context.state.parametros[ context.state.produto ].minImovelValor : 200000)

  validations["EtapaSobreSeuImovel"] = Yup.object().shape({

    imovel_valor: Yup.string()
    .test('test-valor-imovel', `O valor do imóvel precisa ser maior que ${formatMoney(minImovelValor)} para continuar.`, (val) => Number(stripRenda(val)) >= minImovelValor )
    .required('Por favor, digite o valor do imóvel.'),

    imovel_tipo: Yup.string().required('Por favor, selecione o tipo do imóvel.'),

    imovel_quitado: Yup.string().required('Você deve selecionar se o imóvel está quitado.'),

    imovel_divida: Yup.string()
        .when(['imovel_quitado'], {
            is: (val) => val == "Ainda está financiado",
            then: Yup.string().required('Por favor, digite o valor a ser quitado do imóvel.')
    }),

    imovel_alugado: initialValues.hasOwnProperty('imovel_alugado') ?
      Yup.string().required('Informe se o imóvel está alugado.')
    : null,

    imovel_aluguel_valor: Yup.string()
    .when(['imovel_alugado'], {
      is: "Sim",
      then: Yup.string().required('Por favor, digite o valor do aluguel do imóvel.')
    }),

    imovel_matricula_proprietario: initialValues.hasOwnProperty('imovel_matricula_proprietario') ?
      Yup.string().required('Informe se a matrícula do imóvel está em seu nome.')
    :null,

    imovel_matricula_nome: initialValues.hasOwnProperty('imovel_matricula_nome') ?
      Yup.string()
      .when(['imovel_matricula_proprietario'], {
        is: "Não",
        then: Yup.string().required('Informe o nome que consta na matrícula do imóvel.')
      })
    :null,

    imovel_averbado: initialValues.hasOwnProperty('imovel_averbado') ?
      Yup.string().required('Informe se o imóvel está averbado.')
    :null,

    imovel_igual_residencia: initialValues.hasOwnProperty('imovel_igual_residencia') ?
      Yup.string().required('Informe se você mora no imóvel.')
    :null,

    imovel_cep: initialValues.hasOwnProperty('imovel_cep') ? Yup.string()
    .when(['imovel_igual_residencia'], {
      is: (val) => val == "Não",
      then: Yup.string()
      .required('Favor digitar o cep.')
      .matches(/^\d{5}\-\d{3}$/,'CEP incompleto')
    }) : null,

    imovel_endereco: initialValues.hasOwnProperty('imovel_endereco') ? Yup.string()
    .when(['imovel_igual_residencia'], {
      is: (val) => val == "Não",
      then: Yup.string()
      .required('Digite o endereço.')
    }) : null,

    imovel_numero: initialValues.hasOwnProperty('imovel_numero') ? Yup.number()
    .when(['imovel_igual_residencia'], {
      is: (val) => val == "Não",
      then: Yup.number()
      .typeError('Ops, digite apenas números no número do imóvel.')
      .required('Por favor, insira o número do imóvel.')
    }) : null,

    imovel_estado: initialValues.hasOwnProperty('imovel_estado') ? Yup.string()
    .when(['imovel_igual_residencia'], {
      is: (val) => val == "Não",
      then: Yup.string().required('Selecione o estado do imóvel.')
    }) : null,

    imovel_bairro: initialValues.hasOwnProperty('imovel_bairro') ? Yup.string()
    .when(['imovel_igual_residencia'], {
      is: (val) => val == "Não",
      then: Yup.string().required('Por favor, diga qual seu bairro.')
    }) : null,

    imovel_cidade: initialValues.hasOwnProperty('imovel_cidade') ? Yup.string()
    .when(['imovel_igual_residencia'], {
      is: (val) => val == "Não",
      then: Yup.string().required('Digite a cidade do imóvel.')
    }) : null,

    renda: initialValues.hasOwnProperty('renda') ? Yup.string()
    .required('Por favor, diga sua renda.')
    .test('test-renda', 'Valor da renda está fora dos limites permitidos.',
      (val) => Number(stripRenda(val)) >= 1039 && Number(stripRenda(val)) <= 50000)
    : null,

    ocupacao: initialValues.hasOwnProperty('ocupacao') ? Yup.string().required('Selecione sua ocupação.') : null,

  });

  validations["EtapaCpfImovel"] = Yup.object().shape({

    cpf: Yup.string()
    .required('Por favor, insira seu CPF.')
    .test('test-cpf', 'Ops, o CPF é inválido.',
        function(value) {
            return CPF.isValid(value);
        }
    ),
    data_nascimento: Yup.string()
    .required('Digite sua data de nascimento.')
    .test('test-passado', 'Ops, a data é inválida (Formato dd/mm/aaaa).',
          function(value) {
              const data = moment(value, "DD/MM/YYYY", true);
              return data.isValid() && data.isBefore();
          }
      )
      .test('test-maioridade', 'Você precisa ter mais de 18 anos.',
          function(value) {
              const data = moment(value, "DD/MM/YYYY", true);
              const data_now = moment();
              return data.isValid() && data_now.diff(data,'years') >= 18;
          }
      )
      .test('test-maximo', 'Ops, a data é inválida (Formato dd/mm/aaaa).',
          function(value) {
              const data = moment(value, "DD/MM/YYYY", true);
              const data_now = moment();
              return data.isValid() && data_now.diff(data,'years') <= 100;
          }
      ),
  });

  validations["EtapaCreditoPessoalSobreVoce"] = Yup.object().shape({

    data_nascimento: initialValues.hasOwnProperty('data_nascimento') ? Yup.string()
    .required('Digite sua data de nascimento.')
    .test('test-passado', 'Ops, a data é inválida (Formato dd/mm/aaaa).',
        function(value) {
            const data = moment(value, "DD/MM/YYYY", true);
            return data.isValid() && data.isBefore();
        }
    )
    .test('test-maioridade', 'Você precisa ter mais de 18 anos.',
        function(value) {
            const data = moment(value, "DD/MM/YYYY", true);
            const data_now = moment();
            return data.isValid() && data_now.diff(data,'years') >= 18;
        }
    )
    .test('test-maximo', 'Ops, a data é inválida (Formato dd/mm/aaaa).',
        function(value) {
            const data = moment(value, "DD/MM/YYYY", true);
            const data_now = moment();
            return data.isValid() && data_now.diff(data,'years') <= 100;
        }
    ) : null,
    estado_nascimento: initialValues.hasOwnProperty('estado_nascimento') ? Yup.string().required('Informe seu estado de nascimento.') : null,
    cidade_nascimento: initialValues.hasOwnProperty('cidade_nascimento') ?
      Yup.string().required('Informe sua cidade de nascimento.') : null,
    cpf: initialValues.hasOwnProperty('cpf') ? Yup.string()
      .required('Por favor, insira seu CPF.')
      .test('test-cpf', 'Ops, o CPF é inválido.',
          function(value) {
              return CPF.isValid(value);
          }
    ) : null,
    rg: initialValues.hasOwnProperty('rg') ? Yup.string()
      .required('Insira seu RG.') : null,
    data_emissao_rg: initialValues.hasOwnProperty('data_emissao_rg') ? Yup.string()
    .required('Por favor, insira a data de emissão do seu RG.')
    .test('test-passado', 'Ops, a data é inválida (Formato dd/mm/aaaa).',
      function(value) {
        const data = moment(value, "DD/MM/YYYY", true);
        return data.isValid() && data.isBefore();
      }
    ) : null,
    orgao_emissor_rg: initialValues.hasOwnProperty('orgao_emissor_rg') ? Yup.string()
      .required('Insira qual é o orgão emissor do seu RG.') : null,
    estado_emissor_rg: initialValues.hasOwnProperty('estado_emissor_rg') ? Yup.string()
      .required('Selecione o estado onde você fez seu RG.') : null,
    nacionalidade: initialValues.hasOwnProperty('nacionalidade') ? Yup.string()
      .required('Diga qual sua nacionalidade.') : null,
    nome_mae: initialValues.hasOwnProperty('nome_mae') ? Yup.string()
      .matches(/^[^0-9]{2,}(\s[^\s0-9]{2,})+(\s)?$/,'Por favor, verifique o nome.')
      .required('Digite o nome de sua mãe.') : null,
    genero: initialValues.hasOwnProperty('genero') ? Yup.string()
      .required('Selecione seu gênero.') : null,
    estado_civil: initialValues.hasOwnProperty('estado_civil') ? Yup.string()
      .required('Escolha um estado civil.') : null,
    escolaridade: initialValues.hasOwnProperty('escolaridade') ? Yup.string()
      .required('Escolha uma escolaridade.') : null,
    banco: initialValues.hasOwnProperty('naco') ? Yup.string()
      .required('Digite qual seu banco.') : null,
    banco_agencia: initialValues.hasOwnProperty('banco_agencia') ? Yup.string()
      .max(4,'Digite no máximo 4 digitos para sua conta bancária.')
      .required('Digite o número de sua agência bancária.') : null,
    banco_conta: initialValues.hasOwnProperty('banco_conta') ? Yup.string()
      .required('Digite o número de sua conta bancária.')
      .test('conta', 'Sua conta deve ter entre 3 e 15 digitos.', val => val && val.length >= 3 && val.length <= 15) : null,
  });

  validations["EtapaCreditoPessoalDadosProfissionais"] = Yup.object().shape({

    ocupacao: initialValues.hasOwnProperty('ocupacao') ? Yup.string().required('Selecione sua ocupação.') : null,

    cargo: initialValues.hasOwnProperty('cargo') ? Yup.string()
      .required('Selecione sua profissão.') : null,

    empresa: initialValues.hasOwnProperty('empresa') ? Yup.string()
    .test('test-empresa','Por favor, digite o nome da empresa.',function(val){
      let isValid=true;
      if(this.parent.ocupacao!=undefined && this.parent.ocupacao!=undefined){

        if(this.parent.ocupacao=="Autônomo" || this.parent.ocupacao=="Profissional Liberal"){
          if(this.parent.tem_cnpj!=undefined && this.parent.tem_cnpj!=undefined){
            if(this.parent.tem_cnpj=="Sim"){
              isValid = val!=undefined && val!='';
            }
          }
        }else if(this.parent.ocupacao=="Aposentado ou Pensionista" || this.parent.ocupacao=="Outro"){
          return true;
        }else{
          return val;
        }

      }
      return isValid;
    }) : null,

    tem_cnpj: initialValues.hasOwnProperty('tem_cnpj') ? Yup.string()
    .when(['ocupacao'], {
      is: (val) => ( val == "Autônomo" || val =="Profissional Liberal" ),
      then: Yup.string().required('Selecione se possui CNPJ.')
    }) : null,

    cnpj: initialValues.hasOwnProperty('cnpj') ? Yup.string()
    .test('test-ocupacao-cnpj','Por favor, digite o CNPJ da empresa.',function(val){
      let isValid=true;
      if(this.parent.ocupacao!=undefined && this.parent.ocupacao!=undefined){

        if(this.parent.ocupacao=="Autônomo" || this.parent.ocupacao=="Profissional Liberal"){
          if(this.parent.tem_cnpj!=undefined && this.parent.tem_cnpj!=undefined){
            if(this.parent.tem_cnpj=="Sim"){
              isValid = val!=undefined && val!='';
            }
          }
        }else if(this.parent.ocupacao=="Empresário"){
          return val;
        }

      }
      return isValid;
    })
    .test('test-cnpj', 'Ops, o CNPJ é inválido.',
      function(value) {
        let isValid=true;
        if(this.parent.ocupacao!=undefined && this.parent.ocupacao!=undefined){
          if(this.parent.ocupacao=="Autônomo" || this.parent.ocupacao=="Profissional Liberal"){
            if(this.parent.tem_cnpj!=undefined && this.parent.tem_cnpj!=undefined){
              if(this.parent.tem_cnpj=="Sim"){
                return CNPJ.isValid(value);
              }
            }
          }else if(this.parent.ocupacao=="Empresário"){
            return CNPJ.isValid(value);
          }
        }
        return isValid;
      }
    ) : null,

    aposentado_num_beneficio: initialValues.hasOwnProperty('aposentado_num_beneficio') ? Yup.string()
    .when(['ocupacao'], {
      is: (val) => ( val == "Aposentado ou Pensionista" ),
      then: Yup.string()
        .max(20,'Digite no máximo 20 digitos para seu número do benefício.')
        .required('Digite seu número de benefício.'),
    }) : null,

    renda: initialValues.hasOwnProperty('renda') ? Yup.string()
    .required('Por favor, diga sua renda.')
    .test('test-renda', 'Valor da renda está fora dos limites permitidos.', (val) => {
      return Number(stripRenda(val)) >= 1039 && Number(stripRenda(val)) <= 50000;
    }) : null,

    patrimonio: initialValues.hasOwnProperty('patrimonio') ? Yup.string()
    .required('Por favor, informe qual o valor do seu patrimonio.') : null,

  });

  validations["EtapaVeiculoCreditoPessoal"] = Yup.object().shape({

    quitado: initialValues.hasOwnProperty('quitado') ? Yup.string().required('Informe se seu veículo está quitado.') : null,

    veiculo_tipo: initialValues.hasOwnProperty('quitado') ? Yup.string()
    .when(['quitado'], {
      is: (quitado) => {
        return quitado!=undefined && quitado=='Sim';
      },
      then: Yup.string().required('Você deve escolher o tipo do veículo.')
    }) : null,

    veiculo_marca: Yup.string()
    .when(['veiculo_tipo','possui_carro','quitado','quitado'], {
      is: (veiculo_tipo, possui_carro, quitado) => {
        if(quitado==undefined || quitado=='Não') return false;
        if(possui_carro && veiculo_tipo=='moto'){
          return (veiculo_tipo!='moto' && possui_carro!='não');
        }else{
          return (veiculo_tipo != undefined)
        }
      },
      then: Yup.string().required('Insira a marca do seu veículo.'),
    }),

    veiculo_modelo: Yup.string()
    .when(['veiculo_tipo','possui_carro','quitado'], {
      is: (veiculo_tipo, possui_carro, quitado) => {
        if(quitado==undefined || quitado=='Não') return false;
        if(possui_carro && veiculo_tipo=='moto'){
          return (veiculo_tipo!='moto' && possui_carro!='não');
        }else{
          return (veiculo_tipo != undefined)
        }
      },
      then: Yup.string().required('Por favor, insira o modelo de seu veículo.'),
    }),

    veiculo_ano: Yup.string()
    .when(['veiculo_tipo','possui_carro','quitado'], {
      is: (veiculo_tipo, possui_carro, quitado) => {
        if(quitado==undefined || quitado=='Não') return false;
        if(possui_carro && veiculo_tipo=='moto'){
          return (veiculo_tipo!='moto' && possui_carro!='não');
        }else{
          return (veiculo_tipo != undefined)
        }
      },
      then: Yup.string().required('Digite o ano do veículo.'),
    }),

    restricao: Yup.string()
    .when(['quitado'], {
      is:"Sim",
      then: Yup.string().required('Por favor, diga se você tem alguma restrição.'),
    }),

    banco_financiamento: initialValues.hasOwnProperty('quitado') ? Yup.string()
    .when(['veiculo_tipo','quitado'],{
      is: (veiculo_tipo, quitado) => {
        if(quitado==undefined || quitado=='Não') return false;
        return veiculo_tipo != undefined && quitado && quitado=='Não';
      },
      then: Yup.string().required('Por favor, selecione o banco que está financiando o veículo.')
    }) : null,

    proprietario: initialValues.hasOwnProperty('proprietario') ? Yup.string()
    .when(['veiculo_tipo','possui_carro','quitado'], {
      is: (veiculo_tipo, possui_carro, quitado) => {
        if(quitado==undefined || quitado=='Não') return false;
        if(possui_carro && veiculo_tipo=='moto'){
          return (veiculo_tipo!='moto' && possui_carro!='não');
        }else{
          return (veiculo_tipo != undefined)
        }
      },
      then: Yup.string().required('Informe se você é o proprietário do veículo.')
    } ) : null,

    tem_veiculo_placa: initialValues.hasOwnProperty('veiculo_placa') ? Yup.string().required('Por favor, diga se você sabe a placa do veículo.') : null,

    veiculo_placa: initialValues.hasOwnProperty('veiculo_placa') ? Yup.string()
    .when(['veiculo_tipo','tem_veiculo_placa'], {
      is: (veiculo_tipo, tem_veiculo_placa) => {
        if(quitado==undefined || quitado=='Não') return false;
        return (veiculo_tipo != undefined && tem_veiculo_placa && tem_veiculo_placa=='Sim');
      },
      then: Yup.string()
      .required('Informe a placa do veículo.')
      .matches(/[aA-zZ]{3}[0-9][aA-zZ0-9][0-9]{2}/, 'Formato da placa do veículo incorreto.'),
    } ) : null,

  });

  validations["EtapaDocumentos"] = Yup.object().shape({

    cpf: initialValues.hasOwnProperty('cpf') ? Yup.string()
      .required('Por favor, insira seu CPF.')
      .test('test-cpf', 'Ops, o CPF é inválido.',
          function(value) {
              return CPF.isValid(value);
          }
    ) : null,

    rg: initialValues.hasOwnProperty('rg') ? Yup.string().required('Insira seu RG.')
      .test('rg-valida-caracteres', 'Ops, Digite somente números no RG.',
        function (value) {
          let rgValue = value || '';
          let characterNull = true;
          const stringRg = rgValue.split('')
          stringRg.forEach(char => {
            if (!(!isNaN(parseFloat(char)) && isFinite(char))) {
              characterNull = false
            }
          })

          return characterNull
        })
      .test('rg-valida-digitos', 'Ops, o RG deve conter 5 ou mais digitos.',
        function (value) {
          return value.length < 5 ? false : true
        }) : null,

    data_emissao_rg: initialValues.hasOwnProperty('data_emissao_rg') ? Yup.string()
    .required('Por favor, insira a data de emissão do seu RG.')
    .test('test-passado', 'Ops, a data é inválida (Formato dd/mm/aaaa).',
      function(value) {
        const data = moment(value, "DD/MM/YYYY", true);
        return data.isValid() && data.isBefore();
      }
    ) : null,

    orgao_emissor_rg: initialValues.hasOwnProperty('orgao_emissor_rg') ? Yup.string().required('Insira qual é o orgão emissor do seu RG.') : null,

    estado_emissor_rg: initialValues.hasOwnProperty('estado_emissor_rg') ? Yup.string().required('Selecione o estado onde você fez seu RG.') : null,

  });

  validations["EtapaVocePossuiVeiculo"] = Yup.object().shape({

    veiculo_tipo: Yup.string()
    .required('Você deve escolher o tipo do veículo.'),

    veiculo_combustivel: initialValues.hasOwnProperty('veiculo_combustivel')
    ? Yup.string().required('Você deve escolher o tipo de combustível.')
    : undefined,

    veiculo_licenciamento_uf: initialValues.hasOwnProperty('veiculo_licenciamento_uf')
    ? Yup.string().required('Você deve escolher o estado de licenciamento.')
    : undefined,


  });

  validations["EtapaVeiculoMarca"] = Yup.object().shape({

    veiculo_marca: Yup.string().required('Insira a marca do seu veículo.'),

  });

  validations["EtapaVeiculoAno"] = Yup.object().shape({

    veiculo_ano: Yup.string().required('Insira o ano do veículo.'),

  });

  validations["EtapaVeiculoModelo"] = Yup.object().shape({

    veiculo_modelo: Yup.string()
    .required('Por favor, insira o modelo de seu veículo.')
    .when(['veiculo_tipo','possui_carro'], {
      is: (veiculo_tipo, possui_carro) => {
        if(possui_carro && veiculo_tipo=='moto'){
          return (veiculo_tipo!='moto' && possui_carro!='não');
        }else{
          return (veiculo_tipo != undefined)
        }
      },
      then: Yup.string().required('Por favor, insira o modelo de seu veículo.'),
    }),

  });

  validations["EtapaVeiculoVersao"] = Yup.object().shape({

    veiculo_versao: Yup.string().required('Insira a versão do seu veículo.'),

  });


  validations["EtapaVeiculoCheckMolicar"] = Yup.object().shape({
    veiculo_molicar_modelo_versao: Yup.string().required("Por favor, insira a versão do veículo.")
  });

  validations["EtapaVeiculoInfos"] = Yup.object().shape({

    tem_veiculo_placa: initialValues.hasOwnProperty('tem_veiculo_placa') ? Yup.string().required('Por favor, diga se você sabe a placa do veículo.') : null,

    veiculo_placa: initialValues.hasOwnProperty('veiculo_placa') ? Yup.string()
    .when(['tem_veiculo_placa'], {
      is: (tem_veiculo_placa) => {
        return (tem_veiculo_placa && tem_veiculo_placa=='Sim');
      },
      then: Yup.string()
      .required('Informe a placa do veículo.')
      .matches(/[aA-zZ]{3}[0-9][aA-zZ0-9][0-9]{2}/, 'Formato da placa do veículo incorreto.'),
    } ) : null,

    quitado: initialValues.hasOwnProperty('quitado') ? Yup.string()
    .when(['tem_veiculo_placa','interesse'], {
      is: (tem_veiculo_placa, interesse) => {
        return ( (tem_veiculo_placa && tem_veiculo_placa=='Sim') || interesse=='Fazer um empréstimo com meu veículo em garantia' );
      },
      then: Yup.string()
      .required('Informe se seu veículo está quitado.')
    }) : null,

    banco_financiamento: initialValues.hasOwnProperty('quitado') ? Yup.string()
    .when(['quitado'],{
      is: (quitado) => {
        return quitado && quitado=='Não';
      },
      then: Yup.string().required('Por favor, selecione o banco que está financiando o veículo.')
    })
    : null,

    proprietario: initialValues.hasOwnProperty('proprietario') ? Yup.string()
    .when(['tem_veiculo_placa','interesse'], {
      is: (tem_veiculo_placa, interesse) => {
        return ( (tem_veiculo_placa && tem_veiculo_placa=='Sim') || interesse=='Fazer um empréstimo com meu veículo em garantia' );
      },
      then: Yup.string()
      .required('Informe se você é o proprietário do veículo.')
    }) : null,

    interesse: initialValues.hasOwnProperty('interesse') ? Yup.string()
    .when(['tem_veiculo_placa'], {
      is: (tem_veiculo_placa) => {
        return (tem_veiculo_placa && tem_veiculo_placa=='Não');
      },
      then: Yup.string()
      .required('Informe o seu interesse.')
    } ) : null,

    possui_saldo_fgts: context.state.feature_flag_offer_fgts_in_egv && initialValues.hasOwnProperty('possui_saldo_fgts') ? Yup.string()
    .when(['tem_veiculo_placa'], {
      is: (tem_veiculo_placa) => {
        return (tem_veiculo_placa=='Não possuo veículo');
      },
      then: Yup.string().required('Informe se possui saldo no FGTS.')
    } ) : null,

  });

  validations["EtapaVeiculoPlaca"] = Yup.object().shape({

    tem_veiculo_placa: initialValues.hasOwnProperty('veiculo_placa') ? Yup.string().required('Por favor, diga se você sabe a placa do veículo.') : null,

    veiculo_placa: initialValues.hasOwnProperty('veiculo_placa') ? Yup.string()
    .when(['tem_veiculo_placa'], {
      is: (tem_veiculo_placa) => {
        return (tem_veiculo_placa && tem_veiculo_placa=='Sim');
      },
      then: Yup.string()
      .required('Informe a placa do veículo.')
      .matches(/[aA-zZ]{3}[0-9][aA-zZ0-9][0-9]{2}/, 'Formato da placa do veículo incorreto.'),
    } ) : null,

  });

  validations["EtapaElegibilidade"] = Yup.object().shape({

    cpf: initialValues.hasOwnProperty('cpf') ? Yup.string()
    .required('Por favor, insira seu CPF.')
    .test('test-cpf', 'Ops, o CPF é inválido.',
      function(value) {
          return CPF.isValid(value);
      }
    )
    : null,

  });

  return validations[step];

};
