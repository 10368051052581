import React, { useEffect, useState } from "react";
import _ from "lodash";
import axios from "axios";
import * as FipeAPI from "../lib/FipeAPI";
import routesFlows from "../lib/RoutesFlows";
import bvLogo from "../assets/img/partners/bv.png";
import creditasLogo from "../assets/img/partners/creditas.png";
import easyCreditoLogo from "../assets/img/partners/easycredito.png";
import geruLogo from "../assets/img/partners/geru.png";
import { useLocation } from "react-router";
import flows from "../assets/json/flows.json";
import { generate as generateCpf } from "gerador-validador-cpf";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import { getCookie, setCookie } from "../lib/cookies";
import moment from "moment";
import { useHistory } from 'react-router-dom'
import capitalizeWords from "../lib/capitalizeWords"
import { stripRenda } from "../lib/Utils";
import currentApiVersion from "../config/currentApiVersion";

const FormContext = React.createContext();

export const FormProvider = (props) => {

  const history = useHistory();
  const location = useLocation();
  const domContainer = document.querySelector("#quero-financiar-app-container");

  const urlParams = new URLSearchParams(window.location.search);
  const param_montante = urlParams.get("montante");
  let firebaseConfig;
  let apiEndpoint;
  const [firebaseApp, setFirebaseApp] = useState();
  const [authData, setAuthData] = useState();
  const [firebaseLoaded, setFirebaseLoaded] = useState(false);

  // console.log("process.env", process.env.TARGET_ENV);
  // console.log("process.env", process.env.APP_COMMIT_HASH);

  switch (process.env.TARGET_ENV) {
    case "development":
      firebaseConfig = {
        apiKey: "AIzaSyAZvlMt73_CBpoKA1bx7oP-0vcjpoQ1hvo",
        authDomain: "querofinanciar1-staging.firebaseapp.com",
        projectId: "querofinanciar1-staging",
        storageBucket: "querofinanciar1-staging.appspot.com",
        messagingSenderId: "927210197346",
        appId: "1:927210197346:web:89805bc0cd3abf88605c3b",
        measurementId: "G-CLH1G4WYEM",
      };
      apiEndpoint = "http://127.0.0.1:5000/v2";
      break;
    case "staging":
      firebaseConfig = {
        apiKey: "AIzaSyAZvlMt73_CBpoKA1bx7oP-0vcjpoQ1hvo",
        authDomain: "querofinanciar1-staging.firebaseapp.com",
        projectId: "querofinanciar1-staging",
        storageBucket: "querofinanciar1-staging.appspot.com",
        messagingSenderId: "927210197346",
        appId: "1:927210197346:web:89805bc0cd3abf88605c3b",
        measurementId: "G-CLH1G4WYEM",
      };
      apiEndpoint = "https://gateway1-btyd0f36.uc.gateway.dev/v2";
      break;
    case "production":
      firebaseConfig = {
        apiKey: "AIzaSyApnQCAfr1HKinYMB5BDODGUYDct3KSIcs",
        authDomain: "querofinanciar1.firebaseapp.com",
        projectId: "querofinanciar1",
        storageBucket: "querofinanciar1.appspot.com",
        messagingSenderId: "55187286950",
        appId: "1:55187286950:web:160b41f06b0c0f1d123d67",
        measurementId: "G-NSYSWL48FX",
      };
      apiEndpoint = "https://api1-gateway-pcp3352.uc.gateway.dev/v2";
      break;
  }

  useEffect(()=>{

    if(!firebaseLoaded) return

    console.log("============= initContext ==============")

    const initFlow = async ()=> {
      
      const path = location.pathname.split("/")[1];
      let etapas = []
      
      if(
        path != "" &&
        path != "primeiro-acesso" &&
        path != "area-logada" &&
        path != "login" &&
        path != "privacidade"
      ){
        etapas = [...flows[routesFlows(state)[path].flowName]]
      }

      etapas.unshift({
        titulo: "Seu perfil",
        icone: "AttachMoneyIcon",
        resumo:
          "Olá! Estamos muito felizes por ter você aqui. Escolha um produto para começar!",
        component: "EtapaInicial",
        offline: true,
        params: {
          fields: ["produto", "fonteDoLead"],
        },
        action: "iniciar",
      });

      let etapa =
        path != ""
          ? param_montante && Number(param_montante) && param_montante != ""
            ? 3
            : 2
          : 1;


      if(path=="sim-envio-documentos"){
        
        etapas.splice(1,0,{
          titulo: "Formalização",
          icone: "CheckIcon",
          resumo: "Finalizar Proposta",
          component: "EtapaFinalizarPropostaSim",
        })

      }

      if (urlParams.get("debugEtapa"))
        etapa = Number(urlParams.get("debugEtapa"));

      if (urlParams.get("debugEtapaResultado")) {
        etapas = [
          {
            titulo: "Dados enviados!",
            icone: "CheckIcon",
            resumo: "Sua solicitação já está sendo analisada.",
            component: "EtapaResultado",
            params: {
              fields: [],
            },
            action: "final",
          },
        ];
        etapa = 1;
      }

      const produto =
        path != "" &&
        path != "area-logada" &&
        path != "primeiro-acesso" &&
        path != "login" &&
        path != "privacidade" ? routesFlows(state)[path].product : null;

      const fonteDoLead =
        path != "" &&
        path != "area-logada" &&
        path != "primeiro-acesso" &&
        path != "login" &&
        path != "privacidade" ? routesFlows(state)[path].leadSrc : "site";

      changeState({
        showingResultsPage: false,
        etapa,
        etapas,
        fonteDoLead,
        produto,
      });

      let operatingSystem = 'Not known';
      if (window.navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'Windows OS'; }
      if (window.navigator.appVersion.indexOf('Mac') !== -1) { operatingSystem = 'MacOS'; }
      if (window.navigator.appVersion.indexOf('X11') !== -1) { operatingSystem = 'UNIX OS'; }
      if (window.navigator.appVersion.indexOf('Linux') !== -1) { operatingSystem = 'Linux OS'; }
      // console.log(operatingSystem)

      let currentBrowser = 'Not known';
      if (window.navigator.userAgent.indexOf('Chrome') !== -1) { currentBrowser = 'Google Chrome'; }
      else if (window.navigator.userAgent.indexOf('Firefox') !== -1) { currentBrowser = 'Mozilla Firefox'; }
      else if (window.navigator.userAgent.indexOf('MSIE') !== -1) { currentBrowser = 'Internet Exployer'; }
      else if (window.navigator.userAgent.indexOf('Edge') !== -1) { currentBrowser = 'Edge'; }
      else if (window.navigator.userAgent.indexOf('Safari') !== -1) { currentBrowser = 'Safari'; }
      else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'Opera'; }
      else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'YaBrowser'; }
      else { console.log('Others'); }
      // console.log(currentBrowser)

      changeValues({
        operatingSystem,
        currentBrowser
      })

      // window.scrollTo({top: 0, behavior: 'smooth'});

    }

    initFlow()

  },[firebaseLoaded, location])

  useEffect(()=>{

    console.log("================ authData ===============", authData)

    if(firebaseApp){

      if (authData) {
        firebase
        .auth()
        .currentUser.getIdToken()
        .then(async (result) => {

          if(state.feature_flag_login_enabled) await getUserData(authData.uid);

          changeState({
            lastLogin: authData.isAnonymous ? undefined : moment().unix(),
            userLogged: !authData.isAnonymous,
            firebaseLoaded:true,
          });
          
          changeValues({
            veiculo_tabela: state.feature_flag_api_molicar ? "molicar" : "fipe",
            veiculo_fornecedor: state.feature_flag_vehicle_list ? state.feature_flag_vehicle_list : "bv",
            app_commit_hash: process.env.APP_COMMIT_HASH,
            apiVersion: currentApiVersion,
          })

          setFirebaseLoaded(true)

        })

      } else {

        firebase
        .auth()
        .signInAnonymously()
        .then((user) => {
          firebase
          .auth()
          .currentUser.getIdToken()
          .then((result) => {
            changeState({
              firebaseLoaded:true,
            });
            changeValues({
              veiculo_tabela: state.feature_flag_api_molicar ? "molicar" : "fipe",
              veiculo_fornecedor: state.feature_flag_vehicle_list ? state.feature_flag_vehicle_list : "bv",
              app_commit_hash: process.env.APP_COMMIT_HASH,
              apiVersion: currentApiVersion,
            })
            setFirebaseLoaded(true)
          })
        })

      }

      if (
        location.pathname !== '/' &&
        location.pathname !== '/login' &&
        location.pathname !== '/login/' &&
        location.pathname !== '/primeiro-acesso' &&
        location.pathname !== '/primeiro-acesso/' &&
        location.pathname !== '/solicitacao-emprestimo-com-saque-fgts' &&
        location.pathname !== '/solicitacao-emprestimo-com-saque-fgts/' &&
        location.pathname !== '/solicitacao-emprestimo-garantia-veiculo' &&
        location.pathname !== '/solicitacao-emprestimo-garantia-veiculo/' &&
        location.pathname !== '/solicitacao-refinanciamento-de-veiculo' &&
        location.pathname !== '/solicitacao-refinanciamento-de-veiculo/' &&
        location.pathname !== '/sim-envio-documentos' &&
        location.pathname !== '/sim-envio-documentos/' &&
        location.pathname !== '/solicitacao-financiamento-de-veiculo' &&
        location.pathname !== '/solicitacao-financiamento-de-veiculo/' &&
        location.pathname !== '/solicitacao-emprestimo-com-imovel-em-garantia' &&
        location.pathname !== '/solicitacao-emprestimo-com-imovel-em-garantia/' &&
        location.pathname !== '/solicitacao-emprestimo-pessoal' &&
        location.pathname !== '/solicitacao-emprestimo-pessoal/' &&
        location.pathname !== '/privacidade' &&
        location.pathname !== '/privacidade/'
      ) {
        if (location.search === "") {
          if (firebaseConfig.userLogged !== true) {
            firebase.auth().onAuthStateChanged((user) => {
              if (!user || user.isAnonymous) {
                history.push('/login')
              }
            })
          }
        }
      }

    }

  },[authData])

  useEffect(() => {

    if (!firebaseApp) {
      const firebaseConfig = state.FIREBASE_CONFIG;
      setFirebaseApp(firebase.initializeApp(firebaseConfig));
      if (process.env.TARGET_ENV == "development") {
        firebase.firestore().useEmulator("localhost", 8080);
        firebase.auth().useEmulator("http://localhost:9099");
      }
      firebase.analytics();
      firebase.auth().languageCode = 'pt-BR';
    }

    const initFirebase = async () => {

      console.log("============= initFirebase ==============")

      firebase
      .firestore()
      .doc("/config/general")
      .get()
      .then(async (snap) => {
        // console.log("data from firestore",snap.data())

        if (snap.data().feature_flag_api_molicar) {

          //temp for Financiamento Form
          try{
            const tabela = await FipeAPI.consultarTabelaDeReferencia()
            changeState({
              fipe_codigoTabelaReferencia: tabela.data[0].Codigo,
            });
            changeValues({
              fipe_codigoTabelaReferencia: tabela.data[0].Codigo,
            });
          }catch(error){
            console.log(error)
          }

        } else {

          try{
            const tabela = await FipeAPI.consultarTabelaDeReferencia()
            changeState({
              fipe_codigoTabelaReferencia: tabela.data[0].Codigo,
            });
            changeValues({
              fipe_codigoTabelaReferencia: tabela.data[0].Codigo,
            });
          }catch(error){
            console.log(error)
          }

        }

        firebase.auth().onAuthStateChanged( async (user) => {
          setAuthData(user)
          if(user){
            const token = await user.getIdToken()
            console.log('onAuthStateChanged token',token)
          }
        });

        changeState({
          ...snap.data(),
        });
      })
    }

    initFirebase()

  }, []);

  const getUrlParameter = (sParam) => {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split("&"),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=");

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined
          ? true
          : decodeURIComponent(sParameterName[1]);
      }
    }
  };

  const cancelListenToProposalChanges = () => {
    console.log(`cancelListenToProposalChanges!`,state.onSnapshotUnsubscribe)
    if(state.onSnapshotUnsubscribe) state.onSnapshotUnsubscribe()
    changeState({ onSnapshotUnsubscribe: undefined });
  }

  const listenToProposalChanges = (orderId, callback) => {
    if (!orderId || !callback) return;

    console.log("===> listenToProposalChanges");

    console.log("orderId", "==", orderId);
    // console.log("userId", "==", firebase.auth().currentUser.uid);

    const onSnapshotUnsubscribe = firebase
    .firestore()
    .collection("proposals")
    .where("orderId", "==", orderId)
    .where("userId", "==", firebase.auth().currentUser.uid)
    .onSnapshot(
      (querySnapshot) => {
        const proposalsSent = [];
        const proposalsAccepted = [];
        const proposalsPending = [];
        const proposals = [];

        querySnapshot.docs.forEach((doc) => {
          const proposalData = doc.data();
          proposals.push(proposalData);
        });

        callback(proposals);
      },
      (error) => {
        console.log("firestore error", error);
      }
    );

    changeState({ onSnapshotUnsubscribe });
  };

  const getUserProposals = async () => {
    const token = await getFirebaseToken();
    return axios
      .get(`${state.API_ENDPOINT}/leads/${state.values.userId}/proposals`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  }

  const getUserProposal = async (proposalId) => {
    const token = await getFirebaseToken();
    return axios
      .get(`${state.API_ENDPOINT}/proposals/${proposalId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  }

  const getFirebaseToken = () => {
    return firebase.auth().currentUser.getIdToken();
  }

  const getFirebaseUserId = () => {
    return firebase.auth().currentUser.uid;
  }

  const signInWithPhoneNumber = (phoneNumber, recaptchaVerifier) => {
    return firebase
      .auth()
      .signInWithPhoneNumber(`+55${phoneNumber}`, recaptchaVerifier);
  }

  const setFirebaseRecaptchaVerifier = (container) => {
    // console.log("Set recaptcha ============");
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      container,
      {
        size: "invisible",
        callback: (response) => {
          console.log("reCAPTCHA solved, allow signInWithPhoneNumber");
        },
      }
    );
  }

  const showLogin = (showLogin, loginData) => {
    changeState({
      showLogin,
      loginData,
    });
  };

  const showPopup = (showPopup, popupData) => {
    changeState({
      showPopup,
      popupData,
    });
  };

  const login = async (data) => {
    const token = await getFirebaseToken();
    return axios
      .post(`${state.API_ENDPOINT}/auth/login/`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  }

  const checkLoginCode = async (code,loginData) => {
    // console.log("checkLoginCode",code);
    const token = await getFirebaseToken();
    const result = await axios
      .post(`${state.API_ENDPOINT}/auth/check-login-code/`, {
        code,
        email: loginData ? loginData.email : state.loginData.email,
        cpf: loginData ? loginData.cpf : state.loginData.cpf,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    return result.data;
  }

  const checkVehicleYear = async (year) => {
    // console.log("checkVehicleYear",year);
    try{
      const token = await getFirebaseToken();
      const result = await axios
        .post(`${state.API_ENDPOINT}/domains/vehicles/check-year`, {
          year,
          veiculo_fornecedor: state.values.veiculo_fornecedor ? state.values.veiculo_fornecedor : "bv",
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      console.log(result.data)
      return result.data;
    }catch(error){
      console.log(error)
      return {
        success: false,
        data: error.response ? error.response.data : {},
      }
    }

  }

  const signInWithCustomToken = async (token) => {
    const result = await firebase.auth().signInWithCustomToken(token);
    console.log('signInWithCustomToken',result);
    return result.user;
  }

  const getUserData = async (uid) => {

    console.log("===== getUserData ======", uid)

    let userData;

    try{

      // console.log(`getting leads by UID...`);
      const result = await firebase.firestore().doc(`leads/${uid}`).get();
      if(!result.exists){
        changeState({userPersisted:false})
        throw new Error(`cant get lead by uid ${uid}`);
      }
      userData = result.data();
      console.log(`getting lead by UID: success!`,userData);
      changeState({userPersisted:true})

    }catch(error){
      try{

        // console.log(`querying lead by userId...`);
        const result = await firebase.firestore().collection(`leads`).where('userId','==',uid).get();
        if(result.size==0){
          changeState({userPersisted:false})
          throw new Error(`cant query lead by uid ${uid}`);
        }
        userData = result.docs[0].data();
        changeState({userPersisted:true})
        console.log(`querying lead by userId: success!`,result.docs[0].data());

      }catch(error){
        console.log(error);
      }
    }

    if( userData && !_.isEmpty(userData) ){

      const formatedUserData = await formatValues(userData,'frontend')

      const newFormatedData = {
        ...formatedUserData,
      }
      newFormatedData.etapa = undefined;
      newFormatedData.etapa_label = undefined;
      newFormatedData.etapa_action = undefined;
      changeValues(newFormatedData);

      return newFormatedData;
    }else{

      if(state.userLogged===true){
        trackAppEvent('exception', {
          'exDescription': `userdata for ${uid} is empty or null.`,
          'exFatal': false,
        });
      }

    }

  }

  const [state, setState] = React.useState({
    whatsAppNumber:'+5548999692273',
    API_ENDPOINT: apiEndpoint,
    FIREBASE_CONFIG: firebaseConfig,
    etapasOffline: props.etapasOffline ? props.etapasOffline : [1, 2],
    errors: [],
    campoAtivo: 0,
    cidade_options: [],
    cidade_nascimento_options: [],
    // etapa: (param_montante && Number(param_montante) && param_montante!='') ? 2 : props.etapa,
    etapa: props.etapa || 1,
    etapas: props.etapas || [],
    idInternoForm: props.idInternoForm,
    fonteDoLead: "site",
    produto: null,
    parceiros: [],
    proposals: [],
    parceirosInfos: [
      { id: "bv", nome: "BV", logoUrl: bvLogo },
      { id: "geru", nome: "Geru", logoUrl: geruLogo },
      { id: "easycredito", nome: "Easy Crédito", logoUrl: easyCreditoLogo },
      { id: "creditas", nome: "Creditas", logoUrl: creditasLogo },
    ],
    errors: {},
    veiculo_marcas: [],
    fipe_codigoTabelaReferencia: null,
    testLead: urlParams.get("testLead") == "7815696ecbf1c96e6894b779456d330e",
    molicar_options: [],
    loadingPersonalDataUpdate: false,
    showPersonalDataUpdateStatus: false,
    personalDataUpdateStatus: '',
    loadingNextStep: false,
    clickedEmprestimoConstrucao: false,
    clickedEmprestimoImovel: false,
    clickedFinanciamentoImobiliario: false,
    userPersisted:false,
    values: {
      tests:``,
      financeira: props.financeira || null,
      montante:
        param_montante && Number(param_montante) && param_montante != ""
          ? param_montante
          : undefined,
      objetivo: undefined,
      prazo: undefined,
      restricao: undefined,
      nome: undefined,
      celular: undefined,
      email: undefined,
      cep: undefined,
      veiculo_marca: undefined,
      veiculo_modelo: undefined,
      veiculo_ano: undefined,
      veiculo_ano_id: undefined,
      quitado: undefined,
      banco_financiamento: undefined,
      proprietario: undefined,
      endereco: undefined,
      estado: undefined,
      cidade: undefined,
      bairro: undefined,
      numero: undefined,
      complemento: undefined,
      tempo_residencia: undefined,
      tel: undefined,
      data_nascimento: undefined,
      estado_nascimento: undefined,
      cidade_nascimento: undefined,
      ocupacao: undefined,
      aposentado_num_beneficio: undefined,
      renda: undefined,
      patrimonio: undefined,
      tempo_servico: undefined,
      tem_cnpj: undefined,
      nome_mae: undefined,
      nome_referencia: undefined,
      tel_referencia: undefined,
      cpf: undefined,
      rg: undefined,
      veiculo_tipo: undefined,
      fipe_codigoTipoVeiculo: undefined,
      veiculo_fipe_tipo_id: undefined,
      fipe_codigoMarca: undefined,
      fipe_codigoTipoCombustivel: undefined,
      fipe_codigoAno: undefined,
      fipe_codigoModelo: undefined,
      fipe_codigoVeiculo: undefined,
      veiculo_valor: undefined,
      imovel_tipo: undefined,
      imovel_quitado: undefined,
      imovel_valor: undefined,
      imovel_doc: undefined,
      imovel_divida: undefined,
      imovel_cep: undefined,
      imovel_endereco: undefined,
      tipo_casa: undefined,
      data_emissao_rg: undefined,
      orgao_emissor_rg: undefined,
      estado_emissor_rg: undefined,
      nacionalidade: undefined,
      nomeCompleto: undefined,
      genero: undefined,
      estado_civil: undefined,
      escolaridade: undefined,
      banco: undefined,
      banco_label: undefined,
      banco_agencia: undefined,
      banco_conta: undefined,
      banco_tipo_conta: undefined,
      cargo: undefined,
      empresa: undefined,
      employment_status: undefined,
      idade: undefined,
      cnpj: undefined,
      possui_carro: undefined,
      tem_veiculo_placa: undefined,
      veiculo_placa: undefined,
      veiculo_tabela: undefined,
      terms: undefined,
      conjuge_cpf: undefined,
      imovel_alugado: undefined,
      conjuge_compoe_renda: undefined,
      utm_id: urlParams.get("utm_id"),
      utm_source: urlParams.get("utm_source"),
      utm_medium: urlParams.get("utm_medium"),
      utm_campaign: urlParams.get("utm_campaign"),
      utm_term: urlParams.get("utm_term"),
      utm_content: urlParams.get("utm_content"),
      // utm_website_url: window.location.origin+window.location.pathname,
      referrer: urlParams.get("referrer"),
      veiculo_combustivel:'',
    }
  });

  const changeState = (value) => {
    console.log("changeState", value);
    setState((state) => ({ ...state, ...value }));
  };

  const changeValues = (value) => {
    console.log("changeValues", value);
    setState((state) => ({ ...state, values: { ...state.values, ...value } }));
  };

  const getVehicleTypes = async () => {
    const token = await getFirebaseToken();
    try{
      const typesResult = await axios({
        url: `${state.API_ENDPOINT}/domains/vehicles/types`,
        headers: {Authorization: `Bearer ${token}`},
      })
      return typesResult.data
    }catch(error){
      console.log(error)
      return []
    }
  }

  const getVehicleFuels = async () => {
    const token = await getFirebaseToken();
    try{
      const result = await axios({
        url: `${state.API_ENDPOINT}/domains/vehicles/fuels`,
        headers: {Authorization: `Bearer ${token}`},
      })
      return result.data
    }catch(error){
      console.log(error)
      return []
    }
  }

  const getVehicleYears = async () => {
    const token = await getFirebaseToken();
    try{
      const result = await axios({
        url: `${state.API_ENDPOINT}/domains/vehicles/years`,
        headers: {Authorization: `Bearer ${token}`},
        params:{
          veiculo_tipo: state.values.veiculo_tipo,
        },
      })
      return result.data
    }catch(error){
      console.log(error)
      return []
    }
  }

  const getVehicleBrands = async (params={}) => {
    const paramValues = {
      veiculo_tipo: params.veiculo_tipo || state.values.veiculo_tipo,
      veiculo_combustivel: params.veiculo_combustivel || state.values.veiculo_combustivel,
      veiculo_zero_km: params.veiculo_zero_km || false,
      veiculo_ano: params.veiculo_ano || state.values.veiculo_ano,
      veiculo_licenciamento_uf: params.veiculo_licenciamento_uf || state.values.veiculo_licenciamento_uf,
      veiculo_tabela: params.veiculo_tabela || state.values.veiculo_tabela,
    }
    const token = await getFirebaseToken();
    try{
      const result = await axios({
        url: `${state.API_ENDPOINT}/domains/vehicles/brands`,
        headers: {Authorization: `Bearer ${token}`},
        params: paramValues
      })
      return result.data
    }catch(error){
      console.log(error)
      return []
    }
  }

  const getVehicleModels = async () => {
    const token = await getFirebaseToken();
    try{
      const result = await axios({
        url: `${state.API_ENDPOINT}/domains/vehicles/models`,
        headers: {Authorization: `Bearer ${token}`},
        params: {
          veiculo_tipo: state.values.veiculo_tipo,
          veiculo_combustivel: state.values.veiculo_combustivel,
          veiculo_zero_km: false,
          veiculo_ano: state.values.veiculo_ano,
          veiculo_licenciamento_uf: state.values.veiculo_licenciamento_uf,
          veiculo_marca: state.values.veiculo_marca,
          veiculo_tabela: state.values.veiculo_tabela,
        }
      })
      return result.data
    }catch(error){
      console.log(error)
      return []
    }
  }

  const getVehicleVersions = async () => {
    const token = await getFirebaseToken();
    try{
      const result = await axios({
        url: `${state.API_ENDPOINT}/domains/vehicles/versions`,
        headers: {Authorization: `Bearer ${token}`},
        params: {
          veiculo_tipo: state.values.veiculo_tipo,
          veiculo_combustivel: state.values.veiculo_combustivel,
          veiculo_zero_km: false,
          veiculo_ano: state.values.veiculo_ano,
          veiculo_licenciamento_uf: state.values.veiculo_licenciamento_uf,
          veiculo_marca: state.values.veiculo_marca,
          veiculo_modelo: state.values.veiculo_modelo,
          veiculo_tabela: state.values.veiculo_tabela,
        }
      })
      return result.data
    }catch(error){
      console.log(error)
      return []
    }
  }

  const getBrazilianStates = async () => {
    const token = await getFirebaseToken();
    try{
      const result = await axios({
        url: `${state.API_ENDPOINT}/domains/locations/states`,
        headers: {Authorization: `Bearer ${token}`}
      })
      return result.data
    }catch(error){
      console.log(error)
      return []
    }
  }  


  const getBrazilianCitiesByState = async (pState) => {
    const token = await getFirebaseToken();
    try{
      const result = await axios({
        url: `${state.API_ENDPOINT}/domains/locations/states/${pState}/cities`,
        headers: {Authorization: `Bearer ${token}`}
      })
      return result.data
    }catch(error){
      console.log(error)
      return []
    }
  }  


  const getValorVeiculo = (
    marca = "",
    modelo = "",
    ano = "",
    combustivel = "",
    anoCombustivel = ""
  ) => {
    axios
      .get(
        `${state.API_ENDPOINT}/qf-veiculo-valor/${marca}/${ano}/${modelo}/${combustivel}/${anoCombustivel}`
      )
      .then((response) => {
        changeValues({
          veiculo_valor: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const atualizar_ano_modelo_options_sim = (params) => {
    changeState({
      ano_modelo_options: [],
      ano_modelo_options_loading: true,
    });

    const { marca_id } = params;

    axios
      .get(`${state.API_ENDPOINT}/qf-veiculo-anos/${marca_id}`)
      .then((response) => {
        const response_data =
          typeof response.data == "string"
            ? JSON.parse(response.data)
            : response.data;
        changeState({
          ano_modelo_options_loading: false,
          ano_modelo_options: response_data.map((item) => ({
            label: item.description,
            value: item.year,
            combustivel_id: item.fuelCode,
            zeroKm: item.zeroKm,
          })),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const buscarCep = (cep = "") => {
    if (cep == "") cep = state.values.cep;
    return axios.get(`https://viacep.com.br/ws/${cep}/json`).then((res) => {
      if (res.data.erro) return;

      let changeToValues = {
        endereco: res.data.logradouro,
        bairro: res.data.bairro,
        cep: cep,
        estado: res.data.uf,
        cidade: res.data.localidade,
      };

      return changeToValues;
    });
  };

  const consultarMarcasFipe = (tipo) => {
    return FipeAPI.consultarMarcas(
      state.fipe_codigoTabelaReferencia,
      tipo
    ).then((data) => {

      let popularEntries = []
      switch (tipo) {
        case 1:
          popularEntries = [
            "GM - Chevrolet",
            "Citroën",
            "Fiat",
            "Ford",
            "Honda",
            "Hyundai",
            "Peugeot",
            "Renault",
            "Toyota",
            "VW - VolksWagen",
          ];
          break;
        case 2:
          popularEntries =[
            "BMW",
            "YAMAHA",
            "HONDA",
            "SHINERAY",
            "DAFRA",
            "DUCATI",
            "HARLEY-DAVIDSON",
            "KAWASAKI",
            "SUZUKI",
            "VOLTZ",
            "TRIUMPH",
            "TIGER",
            "PEUGEOT",
          ];
          break;
        case 3:
          popularEntries = [
            "HYUNDAI",
            "VOLKSWAGEN",
            "IVECO",
            "JAC",
            "MERCEDES-BENZ",
            "VOLVO",
            "CHEVROLET",
            "FORD",
            "FIAT",
          ];
          break;
      }

      const popular = [];
      const normal = [];

      data.map((item) => {
        if (popularEntries.indexOf(item.label) != -1) {
          popular.push(item);
        } else {
          normal.push(item);
        }
      });

      popular.push({ label: "--", value: "" });

      changeState({ veiculo_marcas: popular.concat(normal) });

      return data;
    });
  };

  const handleLogoutUser = (redirectPath='/login') => {
    changeState({values: {}, userLogged: false})
    firebase.auth().signOut()
    history.push(redirectPath)
  }

  const consultarModelosFipe = (tipo, marca) => {
    return FipeAPI.consultarModelos(
      state.fipe_codigoTabelaReferencia,
      tipo,
      marca
    ).then((data) => {
      changeState({
        veiculo_anos: data.anos,
        veiculo_modelos: data.modelos,
      });

      return data;
    });
  };

  const consultarModelosAtravesDoAnoFipe = (
    tipo,
    marca,
    ano,
    combustivel,
    anoModelo
  ) => {
    return FipeAPI.consultarModelosAtravesDoAno(
      state.fipe_codigoTabelaReferencia,
      tipo,
      marca,
      ano,
      combustivel,
      anoModelo
    ).then((data) => {
      changeState({
        veiculo_modelos: data,
      });

      return data;
    });
  };

  const consultarValorComTodosParametrosFipe = (
    tipo,
    marca,
    ano,
    combustivel,
    anoModelo,
    codigoModelo
  ) => {
    return FipeAPI.consultarValorComTodosParametros(
      state.fipe_codigoTabelaReferencia,
      tipo,
      marca,
      ano,
      combustivel,
      anoModelo,
      codigoModelo
    );
  };

  const checkMolicarMatch = async (fipe) => {

    const token = await getFirebaseToken();

    return axios.get(`${state.API_ENDPOINT}/bv/veiculo/convert-fipe-to-molicar/?fipe=${fipe}`,{ headers: { Authorization: `Bearer ${token}` } }).then((result) => {
      changeState({
        molicar_options: result.data.data.molicar
          .map((molicarItem) => ({
            label: molicarItem.anoModelo
              ? `(${molicarItem.anoModelo}) ${molicarItem.modelo}`
              : molicarItem.modelo,
            value: molicarItem.anoModelo
              ? `(${molicarItem.anoModelo}) ${molicarItem.modelo}`
              : molicarItem.modelo,
            modelo: molicarItem.modelo,
            marca: molicarItem.marca,
            anoModelo: molicarItem.anoModelo,
          }))
          .filter((molicarItem) => molicarItem.anoModelo != "0 km"),
      });
    });
  };

  const consultaAnosMolicar = async () => {
    const token = await getFirebaseToken();
    axios.get(`${state.API_ENDPOINT}/vehicle/molicar/years`, {
      params: {
        veiculo_fornecedor: state.values.veiculo_fornecedor ? state.values.veiculo_fornecedor : "bv"
      },
      headers: {Authorization: `Bearer ${token}`}
    }).then(result => {
      changeState({ veiculo_anos: result.data.data });
      return result;
    })
  }

  const consultarMarcasMolicar = async (tipo, anoVeiculo) => {
    const token = await getFirebaseToken();
    axios.get(`${state.API_ENDPOINT}/vehicle/molicar/brands`, {
      params: {
        flagVeiculoZeroKm: "false",
        descricaoCategoriaVeiculo: tipo? tipo : "LEVES",
        ufLicenciamentoVeiculo: "SP",
        anoModeloVeiculo: anoVeiculo,
        veiculo_fornecedor: state.values.veiculo_fornecedor ? state.values.veiculo_fornecedor : "bv",
      },
      headers: {Authorization: `Bearer ${token}`}
    }).then(result => {

      let popularEntries = []
      switch (tipo) {
        case 'LEVES':
          popularEntries = [
            "CHEVROLET",
            "CITROEN",
            "FIAT",
            "FORD",
            "HONDA",
            "HYUNDAI",
            "PEUGEOT",
            "RENAULT",
            "TOYOTA",
            "VOLKSWAGEN",
          ];
          break;
        case 'MOTO':
          popularEntries =[
            "BMW",
            "YAMAHA",
            "HONDA",
            "SHINERAY",
            "DAFRA",
            "DUCATI",
            "HARLEY-DAVIDSON",
            "KAWASAKI",
            "SUZUKI",
            "VOLTZ",
            "TRIUMPH",
          ];
          break;
        case 'PESADO':
          popularEntries = [
            "HYUNDAI",
            "VOLKSWAGEN",
            "IVECO",
            "JAC",
            "MERCEDES-BENZ",
            "VOLVO",
          ];
          break;
      }

      const popular = [];
      const normal = [];

      result.data.data.marcaVeiculo.map((item) => {
        if (popularEntries.indexOf(item.descricaoMarcaVeiculo) != -1) {
          popular.push({label: item.descricaoMarcaVeiculo, value: item.descricaoMarcaVeiculo, id: item.descricaoMarcaVeiculo});
        } else {
          normal.push({label: item.descricaoMarcaVeiculo, value: item.descricaoMarcaVeiculo, id: item.descricaoMarcaVeiculo});
        }
      });

      popular.push({ label: "--", value: "" });

      changeState({ veiculo_marcas: popular.concat(normal) });

      return result;
    })

  };

  const consultarModelosMolicar = async (tipoVeiculo, anoVeiculo, codigoMarcaVeiculo) => {

    const token = await getFirebaseToken();
    axios.get(`${state.API_ENDPOINT}/vehicle/molicar/models`, {
      params: {
        flagVeiculoZeroKm: "false",
        descricaoCategoriaVeiculo: tipoVeiculo? tipoVeiculo : "LEVES",
        ufLicenciamentoVeiculo: "SP",
        anoModeloVeiculo: anoVeiculo,
        descricaoMarcaVeiculo: codigoMarcaVeiculo,
        veiculo_fornecedor: state.values.veiculo_fornecedor ? state.values.veiculo_fornecedor : "bv"
      },
      headers: {Authorization: `Bearer ${token}`}
    }).then(result => {

      const modelos = [];

      result.data.data.modeloVeiculo.forEach(modelo => {
        modelos.push({
          label: `${modelo.descricaoModeloVeiculoParte1} ${modelo.descricaoModeloVeiculoParte2}`,
          value: `${modelo.descricaoModeloVeiculoParte1} ${modelo.descricaoModeloVeiculoParte2}`,
          id: `${modelo.descricaoModeloVeiculoParte1} ${modelo.descricaoModeloVeiculoParte2}`,
          descricaoModeloVeiculoParte1: modelo.descricaoModeloVeiculoParte1,
          descricaoModeloVeiculoParte2: modelo.descricaoModeloVeiculoParte2
        })
      })

      changeState({ veiculo_modelos: modelos })
    })

  };

  const validaTiposVeiculoMolicar = (veiculo) => {
    switch (veiculo) {
      case 'carro':
        return 'LEVES'
      case 'moto':
        return 'MOTO'
      case 'caminhao':
        return 'PESADO'
      default:
        return 'LEVES'
    }
  }

  const consultaVersoesMolicar = async (tipoVeiculo, anoVeiculo, codigoMarcaVeiculo, descricaoModeloVeiculoParte1, descricaoModeloVeiculoParte2) => {

    const token = await getFirebaseToken();
    axios.get(`${state.API_ENDPOINT}/vehicle/molicar/versions`, {
      params: {
        flagVeiculoZeroKm: "false",
        descricaoCategoriaVeiculo: tipoVeiculo? tipoVeiculo : "LEVES",
        ufLicenciamentoVeiculo: "SP",
        anoModeloVeiculo: anoVeiculo,
        descricaoMarcaVeiculo: codigoMarcaVeiculo,
        descricaoModeloVeiculoParte1,
        descricaoModeloVeiculoParte2,
        veiculo_fornecedor: state.values.veiculo_fornecedor ? state.values.veiculo_fornecedor : "bv"
      },
      headers: {Authorization: `Bearer ${token}`}
    }).then(result => {

      changeState({
        molicar_options: result.data.data.versaoVeiculo
          .map((molicarItem) => ({
            label: molicarItem.descricaoVersaoVeiculo,
            value: molicarItem.descricaoVersaoVeiculo,
            modelo: `${descricaoModeloVeiculoParte1} ${descricaoModeloVeiculoParte2}`,
            marca: codigoMarcaVeiculo,
            anoModelo: '',
          }))
      });

      // changeState({molicar_options: result.data.data.versaoVeiculo})
      return result;

    })
  };

  const createVehicleFlow = (table) => {

    let etapas = _.cloneDeep(state.etapas);
    let stepsToAdd

    etapas = etapas.filter( etapa => ![`EtapaVeiculoMarca`,`EtapaVeiculoAno`,`EtapaVeiculoModelo`].includes(etapa.component) )

    console.log(`table is`,table)

    if(table==`molicar`){
      stepsToAdd = [
        {
          "titulo": "Sobre seu veículo",
          "icone": "CheckIcon",
          "resumo": "Você está a alguns passos de solicitar seu financiamento de %montante%.",
          "component": "EtapaVeiculoAno",
          "params": {
            "fields": [
              "veiculo_ano"
            ]
          }
        },
        {
          "titulo": "Sobre seu veículo",
          "icone": "CheckIcon",
          "resumo": "Você está a alguns passos de solicitar seu financiamento de %montante%.",
          "component": "EtapaVeiculoMarca",
          "params": {
            "fields": [
              "veiculo_marca"
            ]
          }
        },
        {
          "action": "checkMolicarMatch",
          "titulo": "Sobre seu veículo",
          "icone": "CheckIcon",
          "resumo": "Você está a alguns passos de solicitar seu financiamento de %montante%.",
          "component": "EtapaVeiculoModelo",
          "params": {
            "fields": [
              "veiculo_modelo"
            ]
          }
        },
      ]
    }else{
      stepsToAdd = [
        {
          "titulo": "Sobre seu veículo",
          "icone": "CheckIcon",
          "resumo": "Você está a alguns passos de solicitar seu financiamento de %montante%.",
          "component": "EtapaVeiculoMarca",
          "params": {
            "fields": [
              "veiculo_marca"
            ]
          }
        },
        {
          "titulo": "Sobre seu veículo",
          "icone": "CheckIcon",
          "resumo": "Você está a alguns passos de solicitar seu financiamento de %montante%.",
          "component": "EtapaVeiculoAno",
          "params": {
            "fields": [
              "veiculo_ano"
            ]
          }
        },
        {
          "titulo": "Sobre seu veículo",
          "icone": "CheckIcon",
          "resumo": "Você está a alguns passos de solicitar seu financiamento de %montante%.",
          "component": "EtapaVeiculoModelo",
          "params": {
            "fields": [
              "veiculo_modelo"
            ]
          }
        },
      ]
    }

    const stepsBefore = etapas.slice(0, state.etapa)
    console.log("stepsBefore",stepsBefore)

    const stepsAfter = etapas.slice(state.etapa, state.etapas.length)
    console.log("stepsAfter",stepsAfter)

    console.log("stepsToAdd",stepsToAdd)

    console.log(`=========== before`,etapas)
    console.log(`=========== after`,[
      ...stepsBefore,
      ...stepsToAdd,
      ...stepsAfter,
    ])
    
    changeState({ etapas: [
      ...stepsBefore,
      ...stepsToAdd,
      ...stepsAfter,
    ] })

  }

  const nextStepWithoutSubmitForm = () => {
    let _proximaEtapa = state.etapa + 1;
    changeState({loadingNextStep:false, etapa: _proximaEtapa})
  }

  const submitForm = async (values, actions, userPersisted=false) => {

    console.log("submitting...",values, actions, userPersisted)

    const createNewLead = userPersisted ? userPersisted : state.userPersisted

    changeState({loadingNextStep:true})
    const token = await getFirebaseToken();
    const data = _.merge(
      {},
      state.values,
      values,
    );

    changeValues(data)

    if (!state.etapas[state.etapa - 1].offline) {

      const formatedData = await formatValues(data, 'submit');
      const endpoint = createNewLead ? `/leads/${data.userId}/` : `/leads/`
      const method = createNewLead ? `put` : `post`

      console.log("sending to", `${state.API_ENDPOINT}${endpoint}`, method, formatedData);

      const changesToState = {}

      try{

        const leadUpdateResult = await axios({
          method: method,
          url: `${state.API_ENDPOINT}${endpoint}`,
          data: formatedData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        console.log("received from", `${state.API_ENDPOINT}${endpoint}`, method, leadUpdateResult.data);

        const formatedLeadData = await formatValues(leadUpdateResult.data.data, 'submit')

        console.log(`formatedLeadData`,formatedLeadData)

        changesToState.userPersisted = true

        if (formatedLeadData.etapa_action == "resultado") {

          if (state.showingResultsPage) return;

          try{

            console.log("sending to", `${state.API_ENDPOINT}/orders/`, `post`, formatedLeadData);
            
            const orderResult = await axios({
              method: `post`,
              url: `${state.API_ENDPOINT}/orders/`,
              data: formatedLeadData,
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })

            console.log(`received from`,`${state.API_ENDPOINT}/orders/`,orderResult.data)

            if(state.onSnapshotUnsubscribe){
              state.onSnapshotUnsubscribe()
              changesToState.onSnapshotUnsubscribe = undefined
            }

            console.log(`showingResultsPage = true`)
            changesToState.showingResultsPage = true

            setTimeout(() => {
              submitComplete(data, actions, orderResult.data);
            }, 500);

            const orderData = orderResult.data.data
            const orderId = orderData.orderId ? orderData.orderId : orderData

            listenToProposalChanges(orderId, (proposals) => {

              const stateChangesAfterProposalsUpdate = {}

              let showResults = false;
              let showSimuladorBV = null;
              console.log('listenToProposalChanges proposals', proposals)
              
              proposals.map((item) => {
                if (item.status.includes("proposta_aprovada")) {
                  if (item.partnerId == "sim") showResults = true;
                }

                if (
                  item.partnerId == "bv" &&
                  !state.showSimuladorBV
                ) {
                  showSimuladorBV =
                    state.values.pilotoBV == "simulador" &&
                    state.produto ==
                      "Refinanciamento de Veículo" &&
                    item.status == "pre_analise_pendente";
                }
              });

              if (showResults && !state.showingResultsPage) {
                console.log("=> Show results! (firestore)");
                stateChangesAfterProposalsUpdate.proposals = proposals
                stateChangesAfterProposalsUpdate.showingResultsPage = true
                stateChangesAfterProposalsUpdate.showSimuladorBV = !state.showSimuladorBV
                ? showSimuladorBV
                : state.showSimuladorBV

              } else {

                stateChangesAfterProposalsUpdate.proposals = proposals
                stateChangesAfterProposalsUpdate.showSimuladorBV = !state.showSimuladorBV
                ? showSimuladorBV
                : state.showSimuladorBV

              }

              changeState(stateChangesAfterProposalsUpdate)

            });

          }catch(error){
            console.log(error)
            if (error.response) {
              console.log(error.response);
              if (error.response.status === 403) {
    
                if(error.response.data){
                  const errorData = error.response.data;
                  if (errorData.data.code=="LOGIN_REQUIRED" && state.feature_flag_login_enabled) {
                    const resetField = {}
                    resetField[errorData.data.field] = ''
                    changeValues(resetField)
                    showLogin(true, formatedData);
                  }else{
                    showPopup(true, {
                      title: `Ops! Algo deu errado.`,
                      message: errorData.message,
                    });
                  }
    
                }
    
              }
            }
          }
          
        } else {

          setTimeout(() => {
            submitComplete(data, actions, leadUpdateResult.data);
          }, 500);

        }

      }catch(error){
        console.log(error);
        if(actions) actions.setSubmitting(false);

        if (error.response) {
          console.log(error.response);
          if (error.response.status === 403) {

            if(error.response.data){
              const errorData = error.response.data;
              if (errorData.data.code=="LOGIN_REQUIRED" && state.feature_flag_login_enabled) {
                const resetField = {}
                resetField[errorData.data.field] = ''
                changeValues(resetField)
                showLogin(true, formatedData);
              }else{
                showPopup(true, {
                  title: `Ops! Algo deu errado.`,
                  message: errorData.message,
                });
              }

            }
          }
        }

      }

      changeState(changesToState)
      
    } else {
      // console.log("etapa offline");
      setTimeout(() => {
        submitComplete(values, actions);
      }, 500);
    }
  }

  const submitFormAtualizaDados = async (values) => {

    const token = await getFirebaseToken();
    changeState({values})
    const data = _.merge(
      {},
      state.values,
      values,
    );

    const formatedData = await formatValues(data, 'updateUserData');
    const endpoint = data.userId ? `${state.API_ENDPOINT}/leads/${data.userId}` : `${state.API_ENDPOINT}/leads/`;

    changeState({loadingPersonalDataUpdate: true})
    axios({
      url: endpoint,
      data: formatedData,
      method: data.userId ? `PUT` : `POST`,
      headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        changeState({loadingPersonalDataUpdate: false})
        changeState({personalDataUpdateStatus: 'success'})
        changeState({ showPersonalDataUpdateStatus: true })
      })
      .catch((error) => {
        console.log(error);
        changeState({personalDataUpdateStatus: 'warning'})
        changeState({loadingPersonalDataUpdate: false })
        changeState({showPersonalDataUpdateStatus: true})

      });

  }

  const submitComplete = async (values, actions, qfEnviarResponse) => {

    let formatedValues
    if(values){
      formatedValues = await formatValues(values, 'frontend')
    }

    let newObj = formatedValues ? _.merge(state.values, formatedValues) : null;
    newObj.etapa = state.etapa;
    newObj.etapa_action =
      state.etapas[state.etapa - 1].action;
    newObj.etapa_label =
      state.etapas[state.etapa - 1].titulo;
    newObj.userId = getFirebaseUserId();

    trackAppEvent(`preencheu_etapa`,{
      etapa: `${newObj.etapa_label}`,
    });

    if (
      !qfEnviarResponse ||
      state.etapas[state.etapa - 1].action != "final"
    ) {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }

    setTimeout(() => {
      if (props.onProximaEtapa) {
        props.onProximaEtapa(newObj, qfEnviarResponse);
      } else {
        onProximaEtapa(newObj, qfEnviarResponse);
      }
      if (actions) {
        actions.setSubmitting(false);
        actions.setTouched({});
        // console.log(actions);
      }
      
    }, 500);
  }

  const onProximaEtapa = (newObj, qfEnviarResponse) => {
    // console.log("=================================");
    // console.log("onProximaEtapa");
    // console.log("newObj", newObj);
    // console.log("qfEnviarResponse", qfEnviarResponse);
    // console.log("=================================");

    const changesToContext = {};
    let _proximaEtapa = state.etapa + 1;
    let etapas;

    switch (newObj.etapa_action) {
      case "iniciar":
        etapas = _.cloneDeep(state.etapas);
        etapas.push(...flows[routesFlows(state)[newObj.flowName]]);
        changesToContext.etapas = etapas;
        break;
      case 'addSimChances30':
        if (state.feature_flag_sim_30 === true && state.feature_flag_api_v2_sim === true) {
          etapas = _.cloneDeep(state.etapas);
          etapas.splice(state.etapa, 0, {
            "titulo": "Aumente sua chance de cŕedito em até 30%",
            "icone": "CheckIcon",
            "resumo": "Você está a alguns passos de solicitar seu financiamento de %montante%.",
            "component": "EtapaContaSantander",
            "params": {
              "fields": [
                "conta_santander_debito_automatico",
                "banco",
                "banco_agencia",
                "banco_conta"
              ]
            }
          });
          changesToContext.etapas = etapas;
        }
        changesToContext.qfEnviarResponse = qfEnviarResponse;
        break;
      case "checkMolicarMatch":
        if (state.molicar_options.length > 0) {
          if (state.molicar_options.length > 1) {
            etapas = _.cloneDeep(state.etapas);
            etapas.splice(state.etapa, 0, {
              titulo: "Confirme seu veículo",
              icone: "ErrorIcon",
              resumo:
                "Você está a alguns passos de solicitar seu financiamento de %montante%.",
                component: "EtapaVeiculoCheckMolicar",
                params: {
                  fields: ["veiculo_versao"],
                },
              });
            changesToContext.etapas = etapas;
          } else {
            if (state.values.veiculo_tabela==`molicar`) {
              console.log(state.molicar_options[0])
              newObj.veiculo_modelo = state.molicar_options[0].value;
              newObj.veiculo_versao = state.molicar_options[0].value;
            } else {
              newObj.veiculo_versao = state.molicar_options[0].modelo;
              newObj.veiculo_marca = state.molicar_options[0].marca;
              newObj.veiculo_ano = state.molicar_options[0].anoModelo;
            }
          }
        }
        changesToContext.qfEnviarResponse = qfEnviarResponse;
        break;
      case "checkPlate":
        if (newObj.tem_veiculo_placa == "Não" || newObj.veiculo_placa == "") {
          etapas = _.cloneDeep(state.etapas);
          etapas.splice(state.etapa, 0, {
            action: "insertPlate",
            titulo: "Aviso importante",
            icone: "CheckIcon",
            resumo:
              "Você está a alguns passos de solicitar seu financiamento de %montante%.",
            component: "EtapaEscolhaProduto",
            params: {
              fields: ["produto", "veiculo_molicar_modelo"],
              produtoOptions: [
                {
                  label: "SE VOCÊ QUER OS MENORES JUROS",
                  value: "Refinanciamento de Veículo",
                  desc: "Empréstimo com Veículo em Garantia",
                  img: "thumb_refin",
                  route: "/solicitacao-emprestimo-garantia-veiculo",
                  melhorOpcao: true,
                },
                {
                  label: "SE VOCÊ NÃO QUER COLOCAR GARANTIAS",
                  value: "Empréstimo Pessoal",
                  desc: "Empréstimo Pessoal sem Garantias",
                  img: "thumb_pessoal",
                  route: "/solicitacao-emprestimo-pessoal",
                },
                {
                  label: "SE VOCÊ QUER COMPRAR UM VEÍCULO",
                  value: "Financiamento de Veículo",
                  desc: "Comprar um veículo",
                  img: "thumb_fin",
                  route: "/solicitacao-financiamento-de-veiculo",
                },
              ],
            },
          });
          changesToContext.etapas = etapas;
        }
        changesToContext.qfEnviarResponse = qfEnviarResponse;
        break;
      case "insertPlate":
        if (state.produto == "Refinanciamento de Veículo") {
          etapas = _.cloneDeep(state.etapas);
          etapas.splice(state.etapa, 0, {
            titulo: "Placa do veículo",
            icone: "CheckIcon",
            resumo:
              "Você está a alguns passos de solicitar seu financiamento de %montante%.",
            component: "EtapaVeiculoPlaca",
            params: {
              fields: ["tem_veiculo_placa", "veiculo_placa"],
            },
          });
          changesToContext.etapas = etapas;
          changesToContext.qfEnviarResponse = qfEnviarResponse;
        }
        break;
      case "enviarDadosComplementaresBV":
        etapas = _.cloneDeep(state.etapas);
        etapas.splice(state.etapa, 0, {
          titulo: "Obrigado!",
          icone: "CheckIcon",
          resumo: "Tudo pronto!",
          component: "EtapaAgradecimento",
          params: {
            fields: [],
          },
        });
        changesToContext.etapas = etapas;
        changesToContext.qfEnviarResponse = qfEnviarResponse;
        break;
      case "resultado":
        etapas = _.cloneDeep(state.etapas);
        etapas.splice(state.etapa, 0, {
          titulo: "Estamos enviando seus dados para nossos parceiros.",
          icone: "CheckIcon",
          resumo: "Você está a alguns passos de solicitar seu financiamento de %montante%.",
          component: "EtapaEnviar",
          params: {
            fields: [],
          },
          // action: "resultado",
        } )
        changesToContext.etapas = etapas;
        changesToContext.qfEnviarResponse = qfEnviarResponse;
        break;
      // case "resultado":
      //   etapas = _.cloneDeep(state.etapas);
      //   etapas.push({
      //     titulo: "Dados enviados!",
      //     icone: "CheckIcon",
      //     resumo: "Sua solicitação já está sendo analisada.",
      //     component: "EtapaResultado",
      //     params: {
      //       fields: [],
      //     },
      //     action: "final",
      //   });
      //   changesToContext.etapas = etapas;
      //   changesToContext.qfEnviarResponse = qfEnviarResponse;
      //   break;
    }

    const nextFlow =
      state.etapas[state.etapa - 1].nextFlow;
    if (nextFlow) {
      etapas = _.cloneDeep(state.etapas);
      let flowSteps;

      if (nextFlow.hasOwnProperty("field")) {
        flowSteps =
          state[nextFlow.flows[state.values[nextFlow.field]]];
      } else {
        flowSteps = state[nextFlow];
      }

      // console.log("========> new steps:",flowSteps);
      etapas.push(...flowSteps);
      changesToContext.etapas = etapas;
    }

    changesToContext.etapa = _proximaEtapa;
    changesToContext.values = newObj;
    // console.log("changes to state:",changesToContext);
    changeState(changesToContext);

    setTimeout(()=>{
      changeState({ loadingNextStep:false })
    },3000)
  }

  const trackAppEvent = (eventName, eventParams) => {
    console.log("trackAppEvent", eventName, eventParams);
    firebase.analytics().logEvent(eventName, eventParams);

    if(eventName=='preencheu_etapa'){
      window.pagesense = window.pagesense || [];
      window.pagesense.push(['trackEvent', eventParams.etapa]);
    }

  }

  if (urlParams.get("debugEtapaResultado")) {
    listenToProposalChanges();
  }

  if (getUrlParameter("parceiro")) {
    setCookie("parceiro", getUrlParameter("parceiro"));
  }

  if (getUrlParameter("afiliado")) {
    setCookie("afiliado", getUrlParameter("afiliado"));
  }

  if (urlParams.get("debugValues")) {
    const values = {
      fipe_codigoAno: "2021-1",
      // "pilotoBV": "simulador",
      veiculo_marca: "Ford",
      objetivo: "Quitar dívidas",
      etapa_action: "resultado",
      // "produto": "Refinanciamento de Veículo",
      // "userId": "0ahXHE399BZy8nT9TV4r1JbyCsr2",
      cidade: "Guarulhos",
      ocupacao: "Autônomo(a)/ Prof. Liberal",
      numero: "123",
      cep: "07176-280",
      fonteDoLead: "Site: Empréstimo com Garantia de Veículos",
      prazo: "12",
      nome: "Ronaldo",
      veiculo_molicar_marca: "FORD",
      genero: "Masculino",
      nacionalidade: 'Brasileira',
      // "orderId": "hSPWkzPCC0ZQ5zihVe5E",
      fipe_codigoModelo: "6919",
      estado: "SP",
      endereco: "Rua Doutor José Bonifácio Malburg",
      data_nascimento: "28/03/1985",
      estado_nascimento: "SP",
      montante: 6000,
      etapa: 16,
      veiculo_valor: 38365,
      fipe_codigoTipoCombustivel: "1",
      cargo: "VENDEDOR",
      renda: 1200,
      celular: "(41)99123-"+(1000+Math.round(Math.random()*8999)).toString(),
      // celular: "(41)99283-3531",
      veiculo_modelo: "Ka+ Sedan 1.0 SE/SE PLUS TiVCT Flex 4p",
      bairro: "Vila Nova Bonsucesso",
      proprietario: "Sim",
      veiculo_ano: "2015",
      tempo_servico: "Menos de 6 meses",
      cidade_nascimento: "São Paulo",
      nome_mae: "Fulana Cicrana Beltrana",
      ipCliente: "187.182.5.196",
      fipe_codigoTipoVeiculo: 1,
      veiculo_fipe_tipo_id: 1,
      sobrenome: "Ronaldinha Gaucha",
      veiculo_combustivel: "Gasolina",
      veiculo_tipo: "carro",
      veiculo_molicar_modelo: "KA SE 1.0 12V 4P (AG) Completo",
      veiculo_molicar_modelo_part1: "KA",
      veiculo_molicar_modelo_part2: "SE 1.0 12V",
      cpf: generateCpf({ format: true }),
      // cpf: "351.971.928-28",
      tem_veiculo_placa: "Sim",
      veiculo_placa: "MGI-4041",
      email: "testeenvio" + Date.now() + "@gmail.com",
      // email: "leonelcbraz@gmail.com",
      restricao: "Não",
      userAgentCliente:
        "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.107 Safari/537.36",
      rg: "520723881",
      fipe_codigoVeiculo: "003412-6",
      fipe_codigoMarca: "22",
      quitado: "Sim",
      estado_civil: "Casado",
      fipe_anoCombustivel: "2021 Gasolina",
      tem_cnpj: "Sim",
      cnpj: "79.001.569/0001-21",
    };
    changeValues(values);
  }

  const getUserIp = async () => {
    
    let ip

    const token = await getFirebaseToken();

    try{
      const getUserIpResult = await axios.get(`${state.API_ENDPOINT}/getUserIp/`,{
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      ip = getUserIpResult.data
    }catch(error){
      console.log(error)
      ip = ''
    }

    if(!ip){
      try{
        const ipifyResult = await axios.get(`https://api.ipify.org`)
        ip = ipifyResult.data
      }catch(error){
        console.log(error)
        ip = ''
      }
    }

    return ip

  }

  const formatValues = async (data, type) => {

    // console.log(`=============> formatValues ${type}`,data)
  
    const regexNasc = /\d\d\/\d\d\/\d\d\d\d/;
    let nomeCompleto;
    let conjuge_nomeCompleto;

    switch (type) {
      case 'submit':
        const parceiroValue = getCookie('parceiro');
        const afiliadoValue = getCookie('afiliado');
  
        data.fonteDoLead = state.fonteDoLead;
        data.produto = state.produto;
        data.financeira = state.financeira;
        data.parceiro = (parceiroValue) ? parceiroValue : undefined;
        data.afiliado = (afiliadoValue) ? afiliadoValue : undefined;
        
        if(data.nomeCompleto){
          let nomeCompletoArray = data.nomeCompleto.split(' ');
          nomeCompletoArray = nomeCompletoArray.filter( word => word!="").map( word => word.trim() )
  
          nomeCompleto = capitalizeWords(nomeCompletoArray.join(' '))
          
          data.nome = nomeCompletoArray[0];
          
          nomeCompletoArray.shift();
          data.sobrenome = nomeCompletoArray?.join(' ');
          data.nomeCompleto = nomeCompleto
        }
  
        if(data.conjuge_nomeCompleto){
          let conjuge_nomeCompletoArray = data.conjuge_nomeCompleto.split(' ');
          conjuge_nomeCompletoArray = conjuge_nomeCompletoArray.filter( word => word!="").map( word => word.trim() )
  
          conjuge_nomeCompleto = capitalizeWords(conjuge_nomeCompletoArray.join(' '))
          
          data.conjuge_nome = conjuge_nomeCompletoArray[0];
          
          conjuge_nomeCompletoArray.shift();
          data.conjuge_sobrenome = conjuge_nomeCompletoArray?.join(' ');
          data.conjuge_nomeCompleto = conjuge_nomeCompleto
        }
  
        // Extrai valores de todos os campos tipo seleção
        for (let dataProp in data) {
          // console.log(dataProp);
          if(data[dataProp]){
            if(typeof data[dataProp] == 'object' && data[dataProp].hasOwnProperty('value')){
              data[dataProp] = data[dataProp].value;
            }
          }
        }
        
        if (data.data_nascimento !== undefined) {
          if (regexNasc.test(data.data_nascimento)) {
            data.data_nascimento = moment(data.data_nascimento, "DD/MM/YYYY").format('YYYY-MM-DD');
          }
        }
  
        if (data.conjuge_data_nascimento !== undefined) {
          if (regexNasc.test(data.conjuge_data_nascimento)) {
            data.conjuge_data_nascimento = moment(data.conjuge_data_nascimento, "DD/MM/YYYY").format('YYYY-MM-DD');
          }
        }
  
        if(data.data_emissao_rg){
          if(regexNasc.test(data.data_nascimento)){
            data.data_emissao_rg = moment(data.data_emissao_rg,"DD/MM/YYYY").format('YYYY-MM-DD');
          }else{
            data.data_emissao_rg = moment(data.data_emissao_rg).format('YYYY-MM-DD');
          }
        }
  
  
        if(data.montante) data.montante = stripRenda(data.montante);
        if(data.imovel_valor) data.imovel_valor = stripRenda(data.imovel_valor);
        if(data.aposentado_renda) data.aposentado_renda = stripRenda(data.aposentado_renda);
        if(data.assalariado_renda) data.assalariado_renda = stripRenda(data.assalariado_renda);
        if(data.autonomo_renda) data.autonomo_renda = stripRenda(data.autonomo_renda);
        if(data.empresario_renda) data.empresario_renda = stripRenda(data.empresario_renda);
        if(data.renda) data.renda = stripRenda(data.renda);
        if(data.patrimonio) data.patrimonio = stripRenda(data.patrimonio);
        if(data.veiculo_valor) data.veiculo_valor = stripRenda(data.veiculo_valor);
        if(data.email) data.email = data.email.toLowerCase();
        // if(!values.montante) values = data
        
        data.etapa = state.etapa;
        data.etapa_action = state.etapas[state.etapa-1].action;
        data.etapa_label = state.etapas[state.etapa-1].titulo;
        data.userId = getFirebaseUserId();
        data.lastLogin = state.lastLogin;
        data.ipCliente = await getUserIp();
        data.userAgentCliente = window.navigator.userAgent;
  
        data.tests = String(data.tests).replace(`{Form V1}`,``)
        data.tests = String(data.tests).replace(`{Form V2}`,``)
        data.tests += state.feature_flag_egvFlow2 ? `{Form V2}` : `{Form V1}`
  
        data.tests = String(data.tests).replace(`{Tabela Molicar}`,``)
        data.tests = String(data.tests).replace(`{Tabela Fipe}`,``)
        data.tests += data.veiculo_tabela==`molicar` ? `{Tabela Molicar}` : `{Tabela Fipe}`
  
        // console.log(`=============> formatValues ${type} return`,data)
  
        return data
        break;
      case 'fgts':
        const parceiroValueFGTS = getCookie('parceiro');
        const afiliadoValueFGTS = getCookie('afiliado');
  
        data.fonteDoLead = data.fonteDoLead;
        data.produto = data.produto;
        data.financeira = state.financeira;
  
        data.parceiro = (parceiroValueFGTS) ? parceiroValueFGTS : undefined;
        data.afiliado = (afiliadoValueFGTS) ? afiliadoValueFGTS : undefined;
        
        if(data.nomeCompleto){
          let nomeCompletoArray = data.nomeCompleto.split(' ');
          nomeCompletoArray = nomeCompletoArray.filter( word => word!="").map( word => word.trim() )
  
          nomeCompleto = capitalizeWords(nomeCompletoArray.join(' '))
          
          data.nome = nomeCompletoArray[0];
          
          nomeCompletoArray.shift();
          data.sobrenome = nomeCompletoArray?.join(' ');
          data.nomeCompleto = nomeCompleto
        }
  
        if(data.conjuge_nomeCompleto){
          let conjuge_nomeCompletoArray = data.conjuge_nomeCompleto.split(' ');
          conjuge_nomeCompletoArray = conjuge_nomeCompletoArray.filter( word => word!="").map( word => word.trim() )
  
          conjuge_nomeCompleto = capitalizeWords(conjuge_nomeCompletoArray.join(' '))
          
          data.conjuge_nome = conjuge_nomeCompletoArray[0];
          
          conjuge_nomeCompletoArray.shift();
          data.conjuge_sobrenome = conjuge_nomeCompletoArray?.join(' ');
          data.conjuge_nomeCompleto = conjuge_nomeCompleto
        }
  
        // Extrai valores de todos os campos tipo seleção
        for (let dataProp in data) {
          // console.log(dataProp);
          if(data[dataProp]){
            if(typeof data[dataProp] == 'object' && data[dataProp].hasOwnProperty('value')){
              data[dataProp] = data[dataProp].value;
            }
          }
        }
        
        if (data.data_nascimento !== undefined) {
          if (regexNasc.test(data.data_nascimento)) {
            data.data_nascimento = moment(data.data_nascimento, "DD/MM/YYYY").format('YYYY-MM-DD');
          }
        }
  
        if (data.conjuge_data_nascimento !== undefined) {
          if (regexNasc.test(data.conjuge_data_nascimento)) {
            data.conjuge_data_nascimento = moment(data.conjuge_data_nascimento, "DD/MM/YYYY").format('YYYY-MM-DD');
          }
        }
  
        if(data.rg_data_emissao !== undefined){
          if(regexNasc.test(data.rg_data_emissao)){
            data.rg_data_emissao = moment(data.rg_data_emissao,"DD/MM/YYYY").format('YYYY-MM-DD');
          }
        }
  
  
        if(data.montante) data.montante = stripRenda(data.montante);
        if(data.aposentado_renda) data.aposentado_renda = stripRenda(data.aposentado_renda);
        if(data.assalariado_renda) data.assalariado_renda = stripRenda(data.assalariado_renda);
        if(data.autonomo_renda) data.autonomo_renda = stripRenda(data.autonomo_renda);
        if(data.empresario_renda) data.empresario_renda = stripRenda(data.empresario_renda);
        if(data.renda) data.renda = stripRenda(data.renda);
        if(data.patrimonio) data.patrimonio = stripRenda(data.patrimonio);
        if(data.email) data.email = data.email.toLowerCase();
        // if(!values.montante) values = data
        
        data.etapa = data.etapa;
        // data.etapa_action = state.etapas[state.etapa-1].action;
        data.userId = data.userId;
        data.lastLogin = state.lastLogin;
        data.ipCliente = await getUserIp();
        data.userAgentCliente = window.navigator.userAgent;
  
        return data
        break;
      case 'frontend':

        // const regexNasc = /\d\d\d\d\-\d\d\-\d\d/;
        // if (data.data_nascimento && data.data_nascimento !== undefined) {
        //   if (regexNasc.test(data.data_nascimento)) {
        //     data.data_nascimento = moment(data.data_nascimento, "YYYY-MM-DD").format('DD/MM/YYYY')
        //   }
        // }
        // if (data.conjuge_data_nascimento && data.conjuge_data_nascimento !== undefined) {
        //   if (regexNasc.test(data.conjuge_data_nascimento)) {
        //     data.conjuge_data_nascimento = moment(data.conjuge_data_nascimento, "YYYY-MM-DD").format('DD/MM/YYYY')
        //   }
        // }
  
        if(data.nome) data.nome = `${data.nome}`;
        if(data.sobrenome) data.sobrenome = `${data.sobrenome}`;
        if(data.nome && data.sobrenome) data.nomeCompleto = `${data.nome} ${data.sobrenome}`
        
        if (data.data_nascimento) {
          data.data_nascimento = moment(data.data_nascimento,"YYYY-MM-DD").format('DD/MM/YYYY');
        }
        
        if (data.conjuge_data_nascimento) {
          data.conjuge_data_nascimento = moment(data.conjuge_data_nascimento,"YYYY-MM-DD").format('DD/MM/YYYY');
        } 
  
        if(data.data_emissao_rg){
          if(!regexNasc.test(data.data_emissao_rg)){
            data.data_emissao_rg = moment(data.data_emissao_rg,"DD/MM/YYYY").format('YYYY-MM-DD');
          }else{
            data.data_emissao_rg = moment(data.data_emissao_rg).format('YYYY-MM-DD');
          }
        }
  
        // if(data.montante) data.montante = formatFloatToCurrency(data.montante);
        // if(data.aposentado_renda) data.aposentado_renda = formatFloatToCurrency(data.aposentado_renda);
        // if(data.assalariado_renda) data.assalariado_renda = formatFloatToCurrency(data.assalariado_renda);
        // if(data.autonomo_renda) data.autonomo_renda = formatFloatToCurrency(data.autonomo_renda);
        // if(data.empresario_renda) data.empresario_renda = formatFloatToCurrency(data.empresario_renda);
        // if(data.renda) data.renda = formatFloatToCurrency(data.renda);
        // if(data.imovel_valor) data.imovel_valor = formatFloatToCurrency(data.imovel_valor);
        // if(data.veiculo_valor) data.veiculo_valor = formatFloatToCurrency(data.veiculo_valor);
        if (data.email) data.email = data.email.toLowerCase();
        return data
        break;
      case 'updateUserData':
  
        // Extrai valores de todos os campos tipo seleção
        for (let dataProp in data) {
          // console.log(dataProp);
          if(data[dataProp]){
            if(typeof data[dataProp] == 'object' && data[dataProp].hasOwnProperty('value')){
              data[dataProp] = data[dataProp].value;
            }
          }
        } 
  
        if(data.nomeCompleto){
          let nomeCompletoArray = data.nomeCompleto.split(' ');
          nomeCompletoArray = nomeCompletoArray.filter( word => word!="").map( word => word.trim() )
  
          nomeCompleto = capitalizeWords(nomeCompletoArray.join(' '))
          
          data.nome = nomeCompletoArray[0];
          
          nomeCompletoArray.shift();
          data.sobrenome = nomeCompletoArray?.join(' ');
          data.nomeCompleto = nomeCompleto
        }
  
        if(data.conjuge_nomeCompleto){
          let conjuge_nomeCompletoArray = data.conjuge_nomeCompleto.split(' ');
          conjuge_nomeCompletoArray = conjuge_nomeCompletoArray.filter( word => word!="").map( word => word.trim() )
  
          conjuge_nomeCompleto = capitalizeWords(conjuge_nomeCompletoArray.join(' '))
          
          data.conjuge_nome = conjuge_nomeCompletoArray[0];
          
          conjuge_nomeCompletoArray.shift();
          data.conjuge_sobrenome = conjuge_nomeCompletoArray?.join(' ');
          data.conjuge_nomeCompleto = conjuge_nomeCompleto
        }
  
        if(data.data_nascimento) data.data_nascimento = moment(data.data_nascimento, "DD/MM/YYYY").format('YYYY-MM-DD');
        if(data.conjuge_data_nascimento) data.conjuge_data_nascimento = moment(data.conjuge_data_nascimento, "DD/MM/YYYY").format('YYYY-MM-DD');
        if(data.montante) data.montante = stripRenda(data.montante);
        if(data.imovel_valor) data.imovel_valor = stripRenda(data.imovel_valor);
        if(data.aposentado_renda) data.aposentado_renda = stripRenda(data.aposentado_renda);
        if(data.assalariado_renda) data.assalariado_renda = stripRenda(data.assalariado_renda);
        if(data.autonomo_renda) data.autonomo_renda = stripRenda(data.autonomo_renda);
        if(data.empresario_renda) data.empresario_renda = stripRenda(data.empresario_renda);
        if (data.renda) data.renda = stripRenda(data.renda);
        if(data.patrimonio) data.patrimonio = stripRenda(data.patrimonio);
        if(data.veiculo_valor) data.veiculo_valor = stripRenda(data.veiculo_valor);
        if(data.email) data.email = data.email.toLowerCase();
        // if(!values.montante) values = data
        
        data.etapa = state.etapa;
        data.userId = getFirebaseUserId();
        data.lastLogin = state.lastLogin;
        data.ipCliente = await getUserIp();
        data.userAgentCliente = window.navigator.userAgent;
  
        return data
        break;
    }
  }

  const getProposalData = async () => {
      
    const proposalId = new URLSearchParams(location.search).get('proposal')
    if(!proposalId) return

    try{
      const token = await getFirebaseToken();
      const proposalResult = await axios.get(`${state.API_ENDPOINT}/proposals/${proposalId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      return proposalResult.data.data

    }catch(error){
      console.log(error)
    }
    
  }

  // console.log(state)

  return (
    <FormContext.Provider
      value={{
        state,
        domContainer,
        changeState,
        changeValues,
        // atualizar_ano_modelo_options_fipe,
        atualizar_ano_modelo_options_sim,
        buscarCep,
        getVehicleTypes,
        getVehicleFuels,
        getVehicleYears,
        getVehicleBrands,
        getVehicleModels,
        getValorVeiculo,
        consultarMarcasFipe,
        consultarModelosFipe,
        consultarModelosAtravesDoAnoFipe,
        consultarValorComTodosParametrosFipe,
        checkMolicarMatch,
        // consultarAnosMolicar,
        consultaAnosMolicar,
        consultarMarcasMolicar,
        consultarModelosMolicar,
        // showBVFlow,
        listenToProposalChanges,
        cancelListenToProposalChanges,
        showLogin,
        showPopup,
        getFirebaseToken,
        getFirebaseUserId,
        signInWithPhoneNumber,
        setFirebaseRecaptchaVerifier,
        login,
        validaTiposVeiculoMolicar,
        consultaVersoesMolicar,
        handleLogoutUser,
        checkLoginCode,
        signInWithCustomToken,
        submitForm,
        nextStepWithoutSubmitForm,
        submitFormAtualizaDados,
        getUserData,
        getUserProposals,
        getUserProposal,
        trackAppEvent,
        checkVehicleYear,
        createVehicleFlow,
        formatValues,
        getProposalData,
        getBrazilianStates,
        getBrazilianCitiesByState,
        getVehicleVersions,
      }}
    >
      {props.children}
    </FormContext.Provider>
  );
};

export default FormContext;
